<template>
    <div class="bg">
        <div class="e-modal container text-white">
            <div class="b-card">
                <h4 class="pt-5">Token information</h4>
                    <div class="alert alert-danger" v-if="errors.details.show">
                       
                    </div>
    
                <form class="form" v-on:submit.prevent="submitProduct">
                    <div class="form-group">
                        
                        <label for="productName">Product Name:</label>
                        <input type="text" class="form-control mb-3"  id="productname" placeholder="" v-model="init.productName">
                        <p class="help-block"> </p>

                        <label for="description">Description:</label>
                        <input type="text" class="form-control mb-3"  id="price" placeholder="" v-model="init.description">
                        <p class="help-block"> </p>

                        <label for="symbol">Symbol:</label>
                        <input type="text" class="form-control mb-3"  id="price" placeholder="" v-model="init.symbol">
                        <p class="help-block"> </p>

                        <label for="price">Price:</label>
                        <input type="number" class="form-control mb-3"  id="price" placeholder="" v-model="init.price">
                        <p class="help-block"> </p>

                       
                        <button type="button" class="btn btn-upload mb-3"  id="price">Upload icon</button>
                        <p class="help-block"> </p>



                    </div>
                    <div class="form-group ">
                        <button type="submit" class="btn btn-main" id="saveBtn">Submit</button>
                    </div>
                  </form>
                  <div class="logo">
                    <img src="../assets/images/logo.png"/>
                </div>
                </div>
                
        </div>
       
    </div>
</template>
    
<script>
      export default {
        name: 'CreateProduct',
        components: {
            
        },
        data: function() {
          return  {
            icon: "",
            myProfile:"",
            errors: {
              details: {
                show:false,
                msg: ""
              }
            }
          }
        },
        computed: {
          init() {
            return this.$store.state.init;
          },
          show() {
            return this.$store.state.show;
          },
          list() {
            return this.$store.state.list;
          },
        },
        methods: {
        submitProduct: function(){

            if(this.init.productName == "" || this.init.productName == null || this.init.productName == undefined)  {
            this.errors.details.show = true;
            this.errors.details.msg = "This is required";
            return false;
            }

            if(this.init.description == "" || this.init.description == null || this.init.description == undefined)  {
            this.errors.details.show = true;
            this.errors.details.msg = "This is required";
            return false;
            } 


            this.errors.details.show = false;
            const params = {
                productOwner: this.myProfile,
                productName: this.init.productName,
                description: this.init.productName,
                price: this.init.price,
                symbol: this.init.symbol,
                icon: this.icon

            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');


            const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            }, 
            data,
            url:'pub/product/save',
            };

            this.axios(options)
            .then((response)=>{
                this.init.assets = response.data
                
            }).catch((error)=>{
            //    console.log(error.response)
                // if(error.response.data.msgCOde == '00001'){
                //     window.location.href = '/#/login';
                // }
            })

        
            
    
         },
        },
    
        beforeMount(){
          
        }
     }
</script>
    
<style scoped>
        .bg{
          background: rgb(2,11,54);
          width: 100%;
          height: 100vh !important;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
       }
        .logo{
            justify-content: center;
            text-align: center;
            display: flex !important;
        }
        .logo img{
            background-color: transparent;
            width: 7rem;
        }
        .e-modal{
                margin-top: 20px;
                background-image: linear-gradient(180deg, rgba(2,11,54,1) 3%, rgba(1,16,61,1) 35%, rgba(0,31,80,1) 80%);
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding-bottom: 5em;
                width: 50em;
                border-radius: 10px !important;
                box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.336), 0 9px 46px 8px rgba(0, 0, 0, 0.356), 0 11px 15px -7px rgba(0, 0, 0, 0.295)
            }
    
        .form{
            padding: 20px;
            width: 100%;
            display: block;
            justify-content: center !important;
            text-align: center;
            margin: 0 auto !important;
        }
        .form-group{
            width: 100%;
        }
        .btn-upload{
            margin: 30px auto;
            color: white;
            width: 100%;
        }
    
        .btn-main{
            margin: 30px auto;
            color: white;
            width: 100%;
        }
        .btn-main:hover{
            color: white;
        }
    
    </style>