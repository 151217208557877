<template>
  <div class="main">
      <img :src="require('../assets/wallet-bg.png')" class="bg" />

      <div class="main-notfound">
    
          <div class="title">
            <h1 >Your update has been verified.</h1>
          </div>
          <h3>Back to <a href="/#/">HOME</a> page</h3>
      </div>
    
  </div>
</template>

<script>
export default {
  name: 'MobileActivation',
  data: function() {
      return { 
        
      
      }
      },
  methods: {
  confirmEmail: function(){
    console.log('test')
      const params ={
        utoken: this.$route.params.utoken
      }
      const data = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');


    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      data,
      url: '/cx/emailchange/confirm',
    };

    this.axios(options)
      .then((response) => {
       consol.log(response.data)
        localStorage.setItem("utoken", this.$route.params.utoken);

      }).catch((err) => {
        console.log(err)

      })

    },
    confirmMobile(utoken){
      const params ={
        utoken: this.$route.params.utoken
      }
    

      const data = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');


    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      data,
      url: '/cx/mobilechange/confirm',
    };

    this.axios(options)
      .then((response) => {
        consol.log(response.data)
        localStorage.setItem("utoken", response.data.utoken);

      }).catch((err) => {
        console.log(err)

      })

    },
},
mounted() {
     this.confirmMobile();
    
  }
}
</script>

<style scoped>
  .main-notfound{
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center;
      color: white;
  }
  .title{
      padding-top: 10em;
      display: block;
      justify-content: center !important;
      margin: 0 auto;
      align-items: center;
      color: white;
  }
  .title h3{
      color: white;
  }
/* Add custom styles for the "Not Found" page if needed */
</style>
