<template>
  <div >
    <div class="product-card" v-if="product">
      <img :src="require('../assets/crypto/' + product.fromCurrency + '.png')"
      :alt="product.data"
      class="product-image"
      img-top @click="addToCart()">
      <!-- <span class="store-item-icon">
        <b-icon icon="cart" scale="1" shift-h="30" shift-v="50"></b-icon>
      </span> -->
      <h3 class="product-name"> {{ product.fromCurrency != 'BLOXDAO' ? product.fromCurrency: 'BLOX' }} | {{product.toCurrency}}</h3>
      <p class="product-price">{{ formatAmount(product.convertedAmount) }}</p>
      <button class="product-button btn btn-main" @click="addToCart()">BUY</button>
    </div>
    
  </div>
</template>
<script>
    import numeral from 'numeral'

export default {
  props: ["product"],
  name: 'ProductCard',
  data() {
    return {
        assetInfo: [],
        viewProduct: false,
        userId: this.$route.params.userId,
    }
  },
  created: function(){
   let loaded = JSON.parse(localStorage.getItem('user'));

    if(loaded) {
          this.accessToken = loaded.accessToken
          this.userId = loaded.userId
      }
  },
  methods: {
    formatAmount: function(amount) {
			return numeral(amount).format('0,0.00') 
		},
    viewProductInfo:function(content){
        this.$emit('viewProduct')
    },
    close(){
      this.viewProduct = false
    },
    addToCart(){
      let myCart = {
        // userId: this.$route.params.userId,
        product: this.product,
        value: 1,
        // fee: 0.0001,
        image: 'https://store.coexstar.com/assets/crypto/' + this.product.fromCurrency + '.png',
      };
      localStorage.setItem('cart', JSON.stringify(myCart));
     // console.log (myCart);

          this.$router.push({name: 'Cart'})
          

    },
  

  }
};
</script>

<style scoped>
  .main-content {
   padding: 2em;
 }
  .body-wrapper{
     display: flex;
     width: 100% !important;
     justify-content: center !important;
     margin: 0 auto !important;
  }



 .product-grid {
   display: flex;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
   gap: 20px;
   flex-wrap: wrap;
   justify-content: space-evenly;
   padding: 50px;
}

.product-card {
 background-image: linear-gradient(  to right,
         #202538 29%,
         #10121C 59%,
         ) !important;
   box-shadow:0 0 30px rgba(0,0,0,0.3);
   border: 1px solid #202538;
   border-radius: 12px;
   width: 10rem;
   height: 15rem;
   text-align: center;
   padding: 20px;
   margin: 10px !important;
   color: white;

}
.product-card:hover{
   color: white;
   background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
     box-shadow: 5px 10px 30px rgba(0,0,0,0.3);

 }

.product-image {
 padding: 10px 0px;
   position: relative;
   overflow: hidden;
   cursor: pointer;
   margin: 0 auto;
   background-color: none !important;
 width: 100%;
 max-width: 200px;
 height: auto;
 margin-bottom: 10px;
}
.product-image {
   width: 5rem;
   margin: auto auto;
   transition: all 1s ease-in-out;
   display: flex;
   flex-direction: column;
   justify-content: center;
 }
.product-image:hover {
   transform: scale(1.2);
 }

.product-name {
 font-size: 16px;
 margin-bottom: 8px;
 font-family: 'Light';
}

.product-price {
 font-size: 14px;
 color: #888;
 margin-bottom: 8px;
 font-family: 'Light';
}

.product-button {
 margin: 0 auto;
   display: flex;
   justify-content: center;
   border: none !important;
 color: rgb(80, 53, 5);
 border: none;
 padding: 8px 16px;
 border-radius: 4px;
 cursor: pointer;
 background-color: transparent;
 font-family: 'Light';
}

   @media screen and (min-width: 280px) and (max-width: 368px) {
     .product-grid {
           display: grid;
           grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
           gap: 20px;
       }
       .currency{
         width: 100px;
       }
       /* .prodcard {
           width: 5rem;
           height: 9rem;
          
         } */
          .store-img {
             width: 2rem;
            
           }
         .store-item-icon{
           display: none;
         }
         h5, h6{
           font-size: 1rem;
         }
    

   }




</style>