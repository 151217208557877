<template>
<div class="main">
  <img :src="require('../assets/images/bg1.jpg')" class="bg" />
   <viewHeader/>

      <div class="main-container">
      
        <div>
          <h1 class="title"></h1>
        </div>
        <div class="container currency">

              <div class="currency col-3">
                <p class="mx-4">Currency: </p><select name="currency" @change="onChange($event)" v-model="currency" class="search-wrapper form-select form-control" cols="1">
                    <option disabled>Currency</option>
                    <option value="KRW" disabled>KRW</option>
                    <option value="PHP">PHP</option>
                    <option value="USD" disabled>USD</option>
                </select>
            </div>

            
        </div>
        
      </div>
<div class="wrapper">
          <div class="content-wrapper">
            <div class="body-wrapper">
              <div class="product-grid" v-if="this.currency == 'USD'">
                <ProductCard 
                v-for="product in usdArray"
                :key="product.id"
                :product="product"/>
              </div>  
            </div>
          </div>

          <div class="content-wrapper">
            <div class="body-wrapper">
              <div class="product-grid" v-if="this.currency == 'PHP'">
                <ProductCard 
                v-for="product in phpArray"
                :key="product.id"
                :product="product"/>
              </div>  
            </div>
          </div>

          <div class="content-wrapper">
            <div class="body-wrapper">
              <div class="product-grid" v-if="this.currency == 'KRW'">
                <ProductCard 
                v-for="product in krwArray"
                :key="product.id"
                :product="product"/>
              </div>  
            </div>
          </div>
</div>





          <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
          <ChangePin @close="openChangePin" v-if="showChangePin"/>
       
        </div>
</template>
<script>

import numeral from 'numeral'
import ProductCard from "../components/ProductCard.vue"
import LogoutModal from '../components/modals/LogoutModal.vue'
import viewHeader from '../views/viewHeader.vue'
import ChangePin from "../components/modals/ChangePin.vue"



export default {
    name: 'Dashboard',
    components: {
        ProductCard,
        LogoutModal,
        viewHeader,
        LogoutModal,
        ChangePin
    },
    data(){
        return{
          currency: "PHP",
            fee:'0',
            product:'',
            cart: {},
            products:[],
            assetInfo: [],
            sourceCurrency: '',
            sourceCurrencies:[],
            toCurrency:'',
            euro:[],
            usd:[],
            krw:[],
            jpy:[],
            gbp:[],
            php:[],
            aud:[],
            euroArray:[],
            usdArray:[],
            gbpArray:[],
            jpyArray:[],
            krwArray:[],
            phpArray:[],
            audArray:[],
            image: '',
            userId:'',
            showLogoutModal: false,
            status:'',
            showChangePin: false
           
           
        }
    },
     computed: {
        init() {
            return this.$store.state.init;
        },
      },
   
    methods:{
    
      openChangePin(){
          this.showChangePin = !this.showChangePin
        },
      openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
       onChange(event){
          this.currency = event.target.value;
      },
  
        getProfile: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' , 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/profile/',
          };

          this.axios(options)
            .then((response) => {
               
                this.init.profile = response.data;
              console.log(response.data)
              this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress

               this.userId = response.data.entityId
            

              })
      },
     
        logout: function(){
            this.axios.get('/cx/logout')
            .then((response) => { 
              
              window.location.href = "/#/";
                
            }).catch((err) => { 
            //  console.log(err)

            })
        },
        formatAmount: function(amount) {
          return numeral(amount).format('0,0.00') 
        },
        getInventoryList(){
		        const options = {
		          method: 'GET',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
              url: '/pub/store/availablecoins'
		        };
					 this.axios(options)
		            .then((response) => {  
                this.sourceCurrencies = response.data
           //     console.log(this.sourceCurrencies)

                 this.sourceCurrencies.forEach((value, index) => {
            //       console.log(value)
                    this.sourceCurrency = value
                    this.getPhpRate(this.sourceCurrency);
                    this.getUsdRate(this.sourceCurrency);
                    this.getKrwRate(this.sourceCurrency);
                })

		            }).catch((err) => {

		            })

      },
      getUsdRate(sourceCurrency){
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'USD'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.usd = response.data
                  this.usdArray.push(this.usd)
		            }).catch((err) => {

		            })
    },
  
     
     getKrwRate(sourceCurrency){
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'KRW'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		           headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.krw = response.data
                  this.krwArray.push(this.krw)
		            }).catch((err) => {

		            })
    },
    getPhpRate(sourceCurrency){
    
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'PHP'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		           headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.php = response.data
                  this.phpArray.push(this.php)
		            }).catch((err) => {

		            })
    },
   
      clearTimer(){
        localStorage.removeItem("min");
        localStorage.removeItem("sec");
      },
       reloadPage(){
        window.setTimeout( function() {
            window.location.reload();
          }, 59000);
      },

      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
           .then((response) => { 
            this.getProfile();
            this.init.hasSession = true;
              setTimeout( () => {
                this.showLoading = false;
              },1000)
            }).catch((err) => {
                this.init.hasSession = false;
              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/" + this.init.currentLocation.toLowerCase() + "/login";
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/setup-profile";
               },1000)
                
              }  

            })
      },
      
   
     
      



  },
   beforeMount(){
      this.checkSession();
      
    },
   
    mounted(){
      this.getInventoryList();
      localStorage.removeItem('countdownStartTime');
    }
  
}
</script>


<style scoped>
 .wrapper{
        padding: 0em 0em;
  }
.bg{
   width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
  }
  .a-text{
   cursor: pointer;
   text-decoration: none !important;
   font-family: 'Light';
 }
 .a-text:hover{
   border-bottom: 1px solid rgb(14, 6, 81);
 }
  .active{
       border-bottom: 1px solid rgb(14, 6, 81);
       border-radius: 5px;
       text-decoration: none !important;
       font-family: 'Light';
   }

   .main-content {
    padding: 2em;
  }
   .body-wrapper{
      display: flex;
      width: 100% !important;
      justify-content: center !important;
      margin: 0 auto !important;
   }
 
 

 
  .product-grid {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 50px;
}
.currency{
  margin-left: 25px;
}
.product-card {
    background-image: linear-gradient(
            180deg,
          #10184d 29%,
          #00103d 59%,
            #010c2a 88%
    );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 9rem;
    height: 12rem;
    text-align: center;
    padding: 20px;
    margin: 10px !important;
    color: white;
 
}
.product-card:hover{
    color: black;
    background-image: linear-gradient(
            180deg,
            rgb(236, 241, 254) 29%,
            rgb(211, 220, 249) 59%,
            rgb(165, 179, 234) 88%
      );
      box-shadow: 5px 10px 30px rgba(0,0,0,0.3);

  }

.product-image {
  padding: 10px 0px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin: 0 auto;
    background-color: none !important;
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 10px;
}
.product-image {
    width: 5rem;
    margin: auto auto;
    transition: all 1s ease-in-out;
  }
.product-image:hover {
    transform: scale(1.2);
  }

.product-name {
  font-size: 16px;
  margin-bottom: 8px;
}

.product-price {
  font-size: 14px;
  color: #888;
  margin-bottom: 8px;
}

.product-button {
  margin: 0 auto;
    display: flex;
    justify-content: center;
    border: none !important;
  color: rgb(80, 53, 5);
  border: 1px solid white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
    border: 1px solid white;
    background-color: rgb(236, 241, 254) 29%;

}

  	@media screen and (min-width: 280px) and (max-width: 468px) {
      .wrapper{
        padding: 0em 1em;
      }
      .product-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            gap: 20px;
        }
        .currency{
          width: 100px;
        }
        .product-card {
            width: auto !important;
            height: auto !important;
            text-align: center;
            padding: 20px;
        }
        /* .prodcard {
            width: 5rem;
            height: 9rem;
           
          } */
           .store-img {
              width: 2rem;
             
            }
          .store-item-icon{
            display: none;
          }
          h5, h6{
            font-size: 1rem;
          }
     

    }



</style>


