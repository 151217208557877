<template>
<div >
   <!-- <PageLoader/> -->
   <viewHeader></viewHeader>
  <div class="main-content">
  
    <div>
      <h1 class="title">Crypto e-commerce you can trust</h1>
    </div>
    <div class="container">

          <div class="currency col-3">
            <p class="mx-4">Currency: </p><select name="currency" @change="onChange($event)" v-model="currency" class="search-wrapper form-select form-control" cols="1">
                <option disabled>Currency</option>
               
                <option value="KRW">KRW</option>
                <option value="PHP">PHP</option>
                <option value="USD">USD</option>
            </select>
        </div>

         
    </div>
     
  </div>
<div class="wrapper">
  <div class="content-wrapper">
    <div class="body-wrapper">
      <div class="product-grid" v-if="this.currency == 'USD'">
        <div v-for="rate in usdArray" :key="rate.id" class="product-card">
          <img :src="require('../assets/crypto/' + rate.fromCurrency + '.png')" @click="loginModal()"
          :alt="rate.data"
          class="product-image"
          img-top>
          <!-- <span class="store-item-icon">
            <b-icon icon="cart" scale="1" shift-h="30" shift-v="50" @click="loginModal()"></b-icon>
          </span> -->
          <h3 class="product-name"> {{ rate.fromCurrency != 'BLOXDAO' ? rate.fromCurrency: 'BLOX' }} | {{rate.toCurrency}}</h3>
          <p class="product-price">{{ formatAmount(rate.convertedAmount) }}</p>
          <button class="product-button btn btn-main" @click="loginModal()">Add to Cart</button>
        </div>
      </div>  
    </div>
  </div>

  <div class="content-wrapper">
    <div class="body-wrapper">
      <div class="product-grid" v-if="this.currency == 'PHP'">
        <div v-for="rate in phpArray" :key="rate.id" class="product-card">
          <img :src="require('../assets/crypto/' + rate.fromCurrency + '.png')" @click="loginModal()"
          :alt="rate.data"
          class="product-image"
          img-top>
          <!-- <span class="store-item-icon">
            <b-icon icon="cart" scale="1" shift-h="30" shift-v="50" @click="loginModal()"></b-icon>
          </span> -->
          <h3 class="product-name"> {{ rate.fromCurrency != 'BLOXDAO' ? rate.fromCurrency: 'BLOX' }} | {{rate.toCurrency}}</h3>
          <p class="product-price">{{ formatAmount(rate.convertedAmount) }}</p>
          <button class="product-button btn btn-main" @click="loginModal()">Add to Cart</button>
        </div>
      </div>  
    </div>
  </div>

  <div class="content-wrapper">
    <div class="body-wrapper">
      <div class="product-grid" v-if="this.currency == 'KRW'">
        <div v-for="rate in krwArray" :key="rate.id" class="product-card">
          <img :src="require('../assets/crypto/' + rate.fromCurrency + '.png')" @click="loginModal()"
          :alt="rate.data"
          class="product-image"
          img-top>
          <!-- <span class="store-item-icon">
            <b-icon icon="cart" scale="1" shift-h="30" shift-v="50" @click="loginModal()"></b-icon>
          </span> -->
          <h3 class="product-name"> {{ rate.fromCurrency != 'BLOXDAO' ? rate.fromCurrency: 'BLOX' }} | {{rate.toCurrency}}</h3>
          <p class="product-price">{{ formatAmount(rate.convertedAmount) }}</p>
          <button class="product-button btn btn-main" @click="loginModal()">Add to Cart</button>
        </div>
      </div>  
    </div>
  </div>

  </div>


  

            
</div>
</template>
<script>
import numeral from 'numeral'
import viewHeader from "../views/viewHeader.vue";

export default {
   components: {
     viewHeader,
   
  },
  data() {
    return {
      search: '',
      currency: "PHP",
      isCryptoActive: true,
      sourceCurrency: '',
      sourceCurrencies:[],
      toCurrency:'',
      usd:[],
      krw:[],
      php:[],
      usdArray:[],
      krwArray:[],
      phpArray:[],
      showLoginModal: false,
      showSignupModal: false,
      
    };
  },
   computed: {
    init() {
        return this.$store.state.init;
      },
  },
  methods: {
    getInventoryList(){
		        const options = {
		          method: 'GET',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
              url: '/pub/store/availablecoins'
		        };
					 this.axios(options)
		            .then((response) => {  
                this.sourceCurrencies = response.data
               // console.log(this.sourceCurrencies)

                 this.sourceCurrencies.forEach((value, index) => {
                 //  console.log(value)
                    this.sourceCurrency = value
                    this.getPhpRate(this.sourceCurrency);
                    this.getUsdRate(this.sourceCurrency);
                    this.getKrwRate(this.sourceCurrency);
                })

		            }).catch((err) => {

		            })

      },
    onChange(event){
      this.currency = event.target.value;
    },
    loginModal() {
      window.location.href="/#/login"
      // this.showLoginModal = !this.showLoginModal;
      // this.showSignupModal = false
    },
    signupModal() {
      window.location.href="/#/signup"
      // this.showSignupModal = !this.showSignupModal;
      // this.showLoginModal = false
    },
    formatAmount: function(amount) {
			return numeral(amount).format('0,0.00') 
		},
    // getUSDTBuyRate(){
    //   const params = {
    //           toCurrency: 'PHP' ,
    //           merchantId: 'M1686128826446'
    //       }

         
		//         const options = {
		//           method: 'POST',
		//           headers: { 'content-type': 'application/x-www-form-urlencoded' },
    //           params,
		//           url: '/app/rates/usdt/',
		//         };


		// 			 this.axios(options)
		//             .then((response) => {  
    //             console.log(response.data)
		//             }).catch((err) => {

		//             })

    // },
  
    
    getUsdRate(sourceCurrency){
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'USD'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.usd = response.data
                  this.usdArray.push(this.usd)
		            }).catch((err) => {

		            })
    },
     
     getKrwRate(sourceCurrency){
      this.sourceCurrency = sourceCurrency
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'KRW'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.krw = response.data
                  this.krwArray.push(this.krw)
		            }).catch((err) => {

		            })
    },
    getPhpRate(sourceCurrency){
      const params = {
              amount: 1,
              fromCurrency: sourceCurrency,
              toCurrency: 'PHP'
          }

          const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/pub/getxchgrate',
		        };


					 this.axios(options)
		            .then((response) => {  
                this.php = response.data
                  this.phpArray.push(this.php)
		            }).catch((err) => {

		            })
      },

  },
  mounted(){
    this.getInventoryList();
   
  }
  
}
</script>
<style scoped>
   .main-content {
    padding: 2em;
  }
  .wrapper{
    padding: 0em 0em;
  }
   .body-wrapper{
      display: flex;
      width: 100% !important;
      justify-content: center !important;
      margin: 0 auto !important;
   }
 
 

  .product-grid {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 50px;
}

.product-card {
  background-image: linear-gradient(  to right,
          #202538 29%,
          #10121C 59%,
          ) !important;
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid #202538;
    border-radius: 12px;
     width: 10rem;
   height: 15rem;
    text-align: center;
    padding: 20px;
    margin: 10px !important;
    color: white;
}
.product-card:hover{
    color: white;
     background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
      box-shadow: 5px 10px 30px rgba(0,0,0,0.3);

  }

.product-image {
  padding: 10px 0px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin: 0 auto;
    background-color: none !important;
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 10px;
}
.product-image {
    width: 5rem;
    margin: auto auto;
    transition: all 1s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
.product-image:hover {
    transform: scale(1.2);
  }

.product-name {
  font-size: 16px;
  margin-bottom: 8px;
  font-family: 'Light';
}

.product-price {
  font-size: 14px;
  color: #888;
  margin-bottom: 8px;
  font-family: 'Light';
}

.product-button {
  margin: 0 auto;
    display: flex;
    justify-content: center;
    border: none !important;
  color: rgb(80, 53, 5);
  border: none;
  /* padding: 8px 20px; */
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  font-family: 'Light';
}

  	@media screen and (min-width: 280px) and (max-width: 468px) {
      .wrapper{
        padding: 1em;
      }
      .product-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            gap: 20px;
        }
        .product-card {
            width: auto !important;
            height: auto !important;
            text-align: center;
            padding: 20px;
        }
        .currency{
          width: 100px;
        }
           .store-img {
              width: 2rem;
             
            }
          .store-item-icon{
            display: none;
          }
          h5, h6{
            font-size: 1rem;
          }
     

    }




</style>