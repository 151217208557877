<template>
  <div class="main">
    <img :src="require('../../assets/images/bg1.jpg')" class="bg" />
    <viewHeader/>

    <div class="row">
      <div class="column">
        <div class="b-modal">
          <h4 class="d-flex justify-content-center text-white">LOGIN</h4>
          <form class="form" v-on:submit.prevent="onLogin" v-if="login.state == 'login'">
             
            <div class="alert" v-if="errors.login.show" style="color: red;">
                {{ errors.login.msg }}
            </div>
            <div class="form-row container text-center">
                <div class="btn-group text-center ">
                    <button class="btn mobile-btn" type="button"  v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')" >Mobile Number</button>
                    <button class="btn email-btn" type="button" v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">Email Address</button>
                </div>
            </div>
            <div class="form-group" v-if="selected =='email'">
                <label for="formGroupExampleInput">Enter your Email Address:</label>
                <input type="text" class="form-control mb-3" id="formGroupExampleInput" v-model="login.loginId">
                <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''" style="color: red;"> {{ errors.login.loginId }} </p>
            </div>

            <div class="form-group mobile-container" v-if="selected =='mobile' ">
                <label for="loginId">Enter your Mobile Number:</label> 
                <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <button class="btn" type="button" v-on:click="toggleCountryList">
                    <img class="default-flag" :src="require('../../assets/signup_flags/' + country + '.png' )">
                    <span class="text-white">{{ mobileCode }}</span>
                  </button>
                </div>
                <input type="tel" class="form-control" v-model="login.loginId" @keypress="isNumber($event)" maxlength="12">
              </div>
    
              <div class="country-list" v-if="showCountry">
                  <div class="row">
                    <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l" v-on:click="selectCountry(l)">
                      <div >
                        <img class="flag-list-img" :src="require('../../assets/signup_flags/' + l.countryCode + '.png' )">
                      </div>
    
                      <div class="country-name"> <span> {{ l.country }} </span> </div>
                      
                    </div>
                    
                  </div>
                </div>
                 <p style="color: red;" class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
            </div>
    
    
    
            <div class="form-group">
                <label for="exampleFormControlInput1">Enter your 6-digit PIN for Security:</label>
    
                <div class="input-group mb-3">
                  <input type="password" class="form-control" id="inputPass" @keypress="isNumber($event)" maxlength="6" v-model="login.loginPass">
                  <div class="input-group-append">
                    <button class="btn btn-main" id="btnShow" type="button" v-on:click="toggleShowPassword()">{{ hideOrShow }}</button>
                  </div>
                </div>
                
                <p class="help-block" v-if="errors.login.loginPass && errors.login.loginPass !== ''"> {{ errors.login.loginPass }} </p>

                <div>
                <a href="#" v-on:click="toggleLogin('forgot-pass')" class="forgot">Forgot PIN?</a>
              </div>
    
            </div>

            <label for="exampleFormControlInput1">Answer Math Equation Correctly:</label>
            <div id="captcha" class="d-flex justify-content-center mx-auto">
              
                <div>
                    <img :src="`data:image/png;base64,${captchaString}`"  id="captchaImg"/>
                </div>
                <div class="captcha">
                    <input type="text" class="form-control captcha" v-model="captchaSum"  @keypress="isNumber($event)">
                </div>
            </div>


            <div class="form-group ">
                <button type="submit" class="btn btn-main" id="loginBtn">LOG IN</button>
            </div>
            <div class="d-flex justify-content-center">
              <p class="text-white">Don't have an account? <span class="email-btn" @click="goToSignup">Sign up</span></p>
          </div>
           

        </form>

        <form class="form" v-on:submit.prevent="onForgotPassword" v-if="login.state == 'forgot-pass'">

          <div class="danger" v-if="errors.login.show" style="color: red;">
                {{ errors.login.msg }}
            </div>

            <div class="form-row container text-center">
              <div class="btn-group text-center ">
                  <button class="btn mobile-btn" type="button"  v-bind:class="{ active: selected === 'mobile' }" v-on:click="toggleLoginOption('mobile')" >Mobile Number</button>
                  <button class="btn email-btn" type="button" v-bind:class="{ active: selected === 'email' }" v-on:click="toggleLoginOption('email')">Email Address</button>
              </div>
          </div>
          <div class="form-group" v-if="selected =='email'">
              <label for="formGroupExampleInput">Enter your registered Email Address:</label>
              <input type="text" class="form-control mb-3" id="formGroupExampleInput" v-model="login.loginId">
              <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
          </div>

          <div class="form-group mobile-container" v-if="selected =='mobile' ">
              <label for="loginId">Enter your registered Mobile Number:</label> 
              <div class="input-group mb-3">
              <div class="input-group-prepend">
                <button class="btn" type="button" v-on:click="toggleCountryList">
                  <img class="default-flag" :src="require('../../assets/signup_flags/' + country + '.png' )">
                  <span class="text-white">{{ mobileCode }}</span>
                </button>
              </div>
              <input type="tel" class="form-control" v-model="login.loginId" @keypress="isNumber($event)" maxlength="12">
            </div>
  
            <div class="country-list" v-if="showCountry">
                <div class="row">
                  <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l" v-on:click="selectCountry(l)">
                    <div >
                      <img class="flag-list-img" :src="require('../../assets/signup_flags/' + l.countryCode + '.png' )">
                    </div>
  
                    <div class="country-name"> <span> {{ l.country }} </span> </div>
                    
                  </div>
                  
                </div>
              </div>
               <p style="color: red;" class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
          </div>

              <div class="form-group submit-button text-center pt-3">
                  <button class="btn btn-main" id="forgotBtn">Submit </button>
              </div>

              <a href="#" v-on:click="toggleLogin('login')" class="forgot">Back to login</a>
          </form>
        </div>

      </div>
      <div class="column web">
        <img :src="require('../../assets/images/3D-star.png')" class="star"/>
      </div>
    </div>
  </div>
  </div>

</template>

<script>
  import viewHeader from '../../views/viewHeader.vue'

  export default {
    name: 'Login',
    components:
      {
      viewHeader
      },
    data: function() {
      return  {
        otpArr: [], 
        selected: 'mobile',
        country: 'GB',
        mobileCode: '44',
        showCountry: false,
        login: {
          state: 'login',
          showPassword: false,
           loginPass:""
        },
        userId: '',
        accessToken: '',
        nullName: false,
        errors: {
          login: {
            msg: "",
            show: false,
            loginId: "",
            otp: "",
            loginPass: ""
          }
        },
        list: {
          mobileCodes: []
        },
         hideOrShow: "Show",
         captchaString: "",
        captchaSum: "",
        captchaImgSrc: "",
        loginHost: "store.coexstar.com"
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
       captcha() {
            const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded',
                'blox-authz': localStorage.getItem("tokenId")  },
            url: '/cx/login/captcha?fontColor=FFFFFF',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.captchaString = response.data

                })
                .catch((error) => {
                    // Error code goes here
                    this.errors.login.msg = error.response.data.msgText
                    setTimeout(()=>{
                      this.clearError()
                    },4000);
                    
                });
        },
      showSignUp(){
        this.$emit("openSignUp");
      },
      closeModal() {
        this.$emit("close");
      },
      
      onForgotPassword: function() {
        this.errors.login.show = false;

        let loginId = ""; 
              // let loginPass = this.login.loginPass[0] + this.login.loginPass[1] + this.login.loginPass[2] + this.login.loginPass[3] + this.login.loginPass[4] + this.login.loginPass[5];

        if(this.selected == 'mobile') {
                loginId = this.mobileCode + this.login.loginId
        } else {
                loginId = this.login.loginId
        }

        const params = {
            siteHost: "store.coexstar.com",
            loginId: loginId, 
        }

        const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    

        const options = {
            method: 'POST',
            headers: {  'content-type': 'application/x-www-form-urlencoded',
                'blox-authz': localStorage.getItem("tokenId")  },
            data,
            url: '/cx/processforgotpin',
        };

      this.axios(options)
        .then(() => { 
           this.errors.login.show = true;
              this.errors.login.msg = "Temporary PIN has been sent";
              
              this.login.loginId = "";
              setTimeout(()=>{
                      this.clearError()
               },4000);
          }).catch((err) => {
           // console.log(err.response.data)
               this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
                setTimeout(()=>{
                      this.clearError()
                  },4000);
          })

    },
       toggleLogin: function(state) {
        this.errors.login.show = false;
        this.errors.login.msg = "";
        this.login.state = state;
      },
    
      toggleCountryList: function(){
        this.showCountry = !this.showCountry;
      },
      toggleShowPassword: function(){
        this.login.showPassword = !this.login.showPassword;

        if(this.login.showPassword) {
          document.getElementById('inputPass').type = 'text';
          this.hideOrShow = "Hide"

        } else {
          document.getElementById('inputPass').type = 'password';
          this.hideOrShow = "Show"
        }
      },
      toggleLoginOption(selected) {
        this.selected = selected;
        this.login.loginId = "";

        this.errors.login.loginId = '';
        this.errors.login.loginPass = '';

        if(selected == 'email') {
          this.login.loginId = "";
        }
      },
      getMobileCodes: function() {
        this.axios.get('/assets/mobilecodes.json')
          .then((response) => {  
              this.list.mobileCodes = response.data; 
          }).catch((err) => {
           //   console.log(err);

          })
      },
      selectCountry: function(c) {
        this.showCountry = false;
        this.country = c.countryCode;
        this.mobileCode = c.mobileCode; 
      },
      validateEmail: function (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      clearError(){
                this.errors.login.loginId = ""
                this.errors.login.loginPass = ""
                this.errors.login.msg = ""


      },
      onLogin: function() {
            let hasError = false;

            if(this.selected == 'mobile') {
                if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {
                this.errors.login.loginId = "Please provide a valid  Mobile no.";
                hasError = true;
                setTimeout(()=>{s
                      this.clearError()
                    },4000);
                } 

                if(this.login.loginId.length < 10) {
                this.errors.login.loginId = "Please provide a valid  Mobile no.";
                hasError = true;
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                } 

                if(this.login.loginId.substring(0,1) === '0') {
                this.errors.login.loginId = "Please remove 0 in front of your mobile number";
                hasError = true;
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                }
            } 

            if(this.selected == 'email') {
            if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {
                this.errors.login.loginId = "Please provide a valid Email Address.";
                hasError = true;
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                } 

                if(!this.validateEmail(this.login.loginId)) {
                
                this.errors.login.loginId = "Please provide a valid Email Address.";
                hasError = true;
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                }
            }


            if(this.login.loginPass == '' || this.login.loginPass == undefined || this.login.loginPass == null) {
              this.errors.login.loginPass = "Please provide password.";
              hasError = true;
              setTimeout(()=>{
                      this.clearError()
                    },4000);
              }  else {
              if(this.login.loginPass.length < 6) {
                  this.errors.login.loginPass = "PIN must be 6-digits.";
              hasError = true;
              setTimeout(()=>{
                      this.clearError()
                    },4000);
              }
            }



            if(hasError) {
                return;
              }

              this.errors.login.show = false;

              let loginId = ""; 
              // let loginPass = this.login.loginPass[0] + this.login.loginPass[1] + this.login.loginPass[2] + this.login.loginPass[3] + this.login.loginPass[4] + this.login.loginPass[5];

              if(this.selected == 'mobile') {
                loginId = this.mobileCode + this.login.loginId
              } else {
                loginId = this.login.loginId
              }
        

            const params = {
              loginHost: "store.coexstar.com",
              loginId: loginId,
              pin: this.login.loginPass,
              captcha: this.captchaSum
            }

            const data = Object.keys(params)
              .map((key) => `${key}=${encodeURIComponent(params[key])}`)
              .join('&');


            const options = {
             method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded',
                 },
              data,
              url: '/cx/login/pin',
            };

            this.axios(options)
            .then((response) => {
              localStorage.setItem("tokenId", response.data.tokenId);
            //  console.log(localStorage.getItem("tokenId") )
              localStorage.setItem("userId", response.data.userId)
              this.userId = localStorage.getItem("userId")
              let loginData = loginId + ":" + this.login.loginPass + ":"  + this.loginHost

                  let encodedData = window.btoa(loginData);

                  localStorage.setItem("authz", encodedData)
              this.$router.push({name: 'Dashboard'})
              window.location.reload();

               
            }).catch((err) => {
                this.errors.login.show = true;

                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                    
                  }
                }
                if(err.response.data.msgText == "Account is locked out. Please contact customer support or click on 'Forgot your password' link below.')"){
                      this.errors.login.msg = "Account is locked out. Please click on 'Forgot your PIN' link below."
                  }
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                 
          })
         },

        
   

      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },

      otpBack: function(event, currrentBox) {

        if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
      },
      goToSignup(){
        window.location.href="/#/signup"
      },

    },

    beforeMount(){
      this.getMobileCodes();
      
      
    },
    mounted(){
      this.captcha();
    },

    watch: {

    }

    }
</script>

<style scoped>
* {
  box-sizing: border-box;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;

}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}
.column .star{
  width: 40rem;
  object-fit: contain;
  position: relative;
  margin: 10px auto !important;
}
  label{
    color: white;
  }
/*Modal*/
.b-modal{
    width: 500px;
    height: auto;
    margin: 10px auto !important;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    z-index: 2;
    padding: 30px;
  }

  .email-btn{
        background-color: transparent !important;
        border: transparent !important;
        font-size: 15px !important;
        padding: 10px 15px;
        color: white !important;
        }
    .email-btn:hover{
        color: #a16702 !important;
        font-family: 'Bold';
    }
    .email-btn:focus{
        border-bottom: solid 1px #262a41 !important;
        outline: none !important;
        color: #FFFFFF !important;
    }
    .email-btn.active{
        border-bottom: solid 1px #262a41 !important;
        outline: none !important;
        color: #a16702 !important;
    }
    .mobile-btn{
        background-color: transparent !important;
        border-color: transparent !important;
        font-size: 15px !important;
        padding: 10px 20px;
        color: white;
    }
    .mobile-btn:hover{
      color: #a16702 !important;
        font-family: 'Bold';
    }
    .mobile-btn:focus{
        border-bottom: solid 1px #262a41 !important;
    }
    .mobile-btn.active{
        border-bottom: solid 1px #262a41 !important;
        outline: none !important;
        color: #a16702 !important;
    }
    .form{
        width: 80%;
        display: block;
        justify-content: center;
        margin: 0 auto !important;
    }

    .signupBtn {
        border: 1px solid #262a41;
        color: white;
        width: 100%;
        margin-top: 0.5em;
        font-size: 15px;
    }
    .signupBtn:hover{
        background-color:#262a41;
        color: white;
    }
    .btn-main{
      background-image: linear-gradient(to right, #CFA76B 0%, #695536 97%) !important;
        border: none !important;
        color: white;
        width: 100%;
        font-size: 15px;
    }
    .btn-main:hover{
        background-color:#262a41;
        color: white;
    }
    .forgot {
        display: flex;
        justify-content: end;
    }
    a {
        color: #FFFFFF;
        text-decoration: underline;
    }
    .form-control{
        background-color: white;
        border-radius: 9px;
        border: 1px solid transparent;
    }
    .mobile-container {
        position: relative;  
    }
    .default-flag {
        width: 33px;
        margin-right:0.25em;
    }
    .btn{
        border: 1px solid #1a2169;
        
    }
    .country-list {
        position: absolute;
        background: #ccc;
        color: black;
        top: 70px;
        padding: 0.5em;
        height: 13em;
        overflow: auto;
        z-index: 3;
    }
    .country-list .row {
        margin: 0 !important;
    }

    .country-list .col-sm-6 {
      display: flex;
      padding: 0.7em;
      align-items: center;
      cursor: pointer;
    }
    .country-list .row:hover {
      background: darken(#1a2169, 10%);
    }
    .country-name {
      margin-left: 0.5em;
    }
    .country-name span {
        font-size: 0.9em;
    }

    .flag-list-img {
        width: 25px;
    }
    .email-or-mobile {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1em;
    }
    .email-or-mobile .btn {
          color: #FFFFFF;
          border: none;
          outline: none;
          box-shadow: none;
          border-radius: 0;
    }
    .btn-primary{
      background-color: #1a2169;
      color: white;
      font-size: 15px;
    }
    .email-or-mobile .btn.active {
        color: #ffffff;
        border-bottom: 1px solid #FFFFFF;
      }
    
    .email-or-mobile .button {
        color: #FFFFFF;  
    }

    .wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2em;
        width: 25em;
      }
    .wrapper form {
        color: #FFF;
    }
    .otp-wrapper {
        align-items: center;
        display: flex;
        width: 60%;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 1em;
    } 
    .otp-wrapper div {
        margin: 0 .25em;
    }
    .otp-wrapper .otp-input {
        width: 47px;          
        text-align: center;
        border-radius: 6px;
        box-shadow: none;
        font-size: 2em; 
        border: 1px solid  #262d33;
        box-shadow: none;
        outline: none; 
        background: #FFFFFF;
        color: #FFFFFF;
        border-radius: 6px;
        height: 60px;
    }
    
    .footer{
      display: flex;
      justify-content: center;
      bottom: 0 !important;
    }
    .alert-danger{
      background-color: none !important;
      border: none !important;
    }
    .captcha .form-control {
        padding: 0px 5px !important;
        margin-bottom: 10px !important;
        font-size: 2.5rem !important;
        border-bottom: 1px solid white;
        border-radius: 4px;
        color: white !important;
        text-align: center;
        font-family: 'Light';
        background: transparent;

      }
      	@media screen and (min-width: 280px) and (max-width: 768px) {
          .web{
            display: none;
          }
          .column {
              width: 100%;
          }
          .b-modal{
              width: 400px;
              height: auto;
              margin: 100px auto;
              background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
              border-radius: 10px;
              z-index: 2;
              padding: 30px;
            }

      
        .mobile-container .text-center, label{
          font-size: 10px !important;
        }
        #captcha img{
          width: 8rem !important;
        }
        .mobile-btn, .btn-primary, #loginBtn, .signupBtn, .email-btn, p{
          font-size: 10px !important;
        }
        .captcha .form-control {
          padding: 5px !important;
          border-radius: 4px;
          font-size: 1rem !important;
          text-align: center;
          background: transparent;
          border-bottom: 1px solid white;
        }
      }



</style>