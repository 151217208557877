<template>
    <div class="main">
       <img :src="require('../assets/images/bg1.jpg')" class="bg" />

        <div class="main-notfound">
      
            <div class="title">
              <h1 >Page Not Found</h1>
            </div>
            <p>The requested page does not exist.</p>
            <h3>Back to <a href="/#/store">HOME</a> page</h3>
        </div>
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  };
  </script>
  
  <style scoped>
    .main-notfound{
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
    }
    .title{
        padding-top: 10em;
        display: block;
        justify-content: center !important;
        margin: 0 auto;
        align-items: center;
    }
    .title h3{
        color: white;
    }
  /* Add custom styles for the "Not Found" page if needed */
  </style>
  