<template>
  <div class="main-content">
    <img :src="require('../assets/images/bg1.jpg')" class="bg" />
    <div class="header-wrapper">
      <!-- <a href="/">
        <img class="logo" :src="require('@/assets/logo.png')" >
      </a>  -->
      <viewHeader/>
      
    </div> 
    <div class="wrapper">
      <div class="progress-bars">
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 1 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 2 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 3 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 4 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 5 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 6 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 7 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 8 }"></div>
      </div>
      <h3 class="text-white d-flex justify-content-center"> SETUP CORPORATE ACCOUNT</h3>
    </div>
    
    <div class="content-wrapper">
      <div class="modal-body">
        <div class="alert alert-danger"  v-if="errors.profile.show">
          {{ errors.profile.msg }}
        </div>
        <div>
          
          <div v-if="this.$route.params.step == 3">
            <form v-on:submit.prevent="goBack" >
              <br>
              <h5 class="text-white">Business Information</h5>
               <fieldset>
                <div class="box2">
                  <label for="bType">Business Type:<strong>*</strong></label>
                  <select id="bType" v-model="init.corpDetails.corpInfo.businessType" class="form-control form-select">
                    <option value="" disabled>Select business type</option>
                    <option value="PRIVATE">Private Company</option>
                    <option value="SOLE">Sole Proprietor</option>
                    <option value="PARTNER">Partnereship</option>
                    <option value="TRUST">Trust</option>
                    <option value="ASSOC">Association</option>
                  </select>
                  </div>
                <div class="box2" >
                  <label for="formGroupExampleInput"> Business Name:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.businessName" placeholder="Registered Business name">
                </div>
              </fieldset>
                <fieldset>
                  <div class="box" >
                    <label for="formGroupExampleInput"> Business Number:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.businessNumber" placeholder="Registered Business number">
                  </div>
                  <div class="box">
                    <label for="bType">Gov't Issuing Agency:<strong>*</strong></label>
                    <select id="bType" v-model="init.corpDetails.corpInfo.issuingAgency" class="form-control form-select">
                      <option value="" disabled>Select Agency</option>
                      <option value="BIR">BIR</option>
                      <option value="DTI">DTI</option>
                      <option value="SEC">SEC</option>
                      <option value="IC">IC</option>
                      <option value="OTHER">Others</option>
                    </select>
                    </div>
                    <div class="box mobile-container">
                      <label for="loginId">Country of Issuance:<strong>*</strong></label>
                      <div class="input-group mb-3">
                        <!-- <div class="input-group-prepend">
                          <button class="btn btn-flag" type="button" v-on:click="toggleCountryList">
                            <img class="default-flag" :src="require('@/assets/signup_flags/' + business.countryIssuance + '.png' )">
                         
                          </button>
                        </div> -->
                        <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.issuanceCountry"  placeholder="select country of issuance"> 
                      </div>
                      <div class="country-list" v-if="showCountry">
                        <div class="row">
                          <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)" >
                            <div>
                              <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                            </div>
                            <div class="country-name">
                              <span> {{ l.country }} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </fieldset>
                <br/>
                  <h5 class="legend">Registered Business Address</h5>
                <fieldset>
                      <div class="box2">
                        <label for="address">Street:<strong>*</strong></label>
                        <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpOfficeAddress.numberStreet">
                      </div>
                     
                      <div class="box2">
                        <label for="business">City:<strong>*</strong></label>
                        <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpOfficeAddress.cityTown">
                      </div>
                    </fieldset>
                    <fieldset>
                      <div class="box">
                        <label for="business">Province:<strong>*</strong></label>
                        <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpOfficeAddress.provState">
                      </div>
                      <div class="box">
                        <label for="business">Zipcode:<strong>*</strong></label>
                        <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpOfficeAddress.postalCode">
                      </div>
                      <div class="box mobile-container">
                        <label for="business">Country:<strong>*</strong></label>
                        <div class="input-group mb-3">
                          <!-- <div class="input-group-prepend">
                            <button class="btn btn-flag" type="button" v-on:click="toggleCountryListforAddress" >
                              <img class="default-flag" :src="require('@/assets/signup_flags/' + business.officeAddress.country + '.png' )">
                           
                            </button>
                          </div> -->
                          <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="init.corpDetails.corpOfficeAddress.country">
                        </div>
                        <div class="country-list" v-if="showCountryForAddress">
                          <div class="row">
                            <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForAddress(l)" >
                              <div>
                                <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                              </div>
                              <div class="country-name">
                                <span> {{ l.country }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  <div class="form-group ">
                    <p class="text-white" style="color: red;">{{errorMsg}}</p>
                    <button type="submit" class="btn btn-main" id="loginBtn">Next</button>
                  </div>
            </form>
          </div>
         
       
          <div v-if="this.$route.params.step == 4">
            <form v-on:submit.prevent="goBack"  class="enhanced">
              <br>
              <h5 class="text-white">Company Information</h5>
              <hr>
              <fieldset>
                <label for="address">Do you have a Trading Name?<strong>*</strong></label>
                <div class="box input-group mb-3">
                  <div class="input-group-prepend">
                    <p class="label input-group" for="trading_name" >No</p>
                  </div>
                  <input class="form-check" type="radio" v-model="hasTradingName" @change="onChange($event)" name="trading_name" :value="false">
                </div>
                <div class="box input-group mb-3">
                  <div class="input-group-prepend">
                    <p class="label input-group" for="trading_name" >Yes</p>
                  </div>
                  <input class="form-check" type="radio" v-model="hasTradingName" @change="onChange($event)" name="trading_name" :value="true">
                </div>
              
              
              <div class="box2" v-if="hasTradingName == 'true'">
                <label for="address">Trading Name:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.tradingName" >
              </div>
            </fieldset>
         
              <div v-if="hasTradingName == 'true'">
                <fieldset >
                  <div class="box">
                    <label for="address">Street:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.tradingAddress.numberStreet">
                  </div>
               
                  <div class="box">
                    <label for="business">City:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.tradingAddress.cityTown">
                  </div>
                
                  <div class="box">
                    <label for="business">Province:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.tradingAddress.provState">
                  </div>
                </fieldset>
                <fieldset>
                  
                <div class="box2 mobile-container">
                  <label for="business">Country:<strong>*</strong></label>
                  <div class="input-group mb-3">
                    <!-- <div class="input-group-prepend">
                      <button class="btn btn-flag" type="button" v-on:click="toggleCountryListforAddress" >
                        <img class="default-flag" :src="require('@/assets/signup_flags/' + business.officeAddress.country + '.png' )">
                     
                      </button>
                    </div> -->
                    <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="init.corpDetails.tradingAddress.country"  >
                  </div>
                  <div class="country-list" v-if="showCountryForAddress">
                    <div class="row">
                      <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForAddress(l)" >
                        <div>
                          <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                        </div>
                        <div class="country-name">
                          <span> {{ l.country }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box2">
                  <label for="business">Zipcode:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.tradingAddress.postalCode">
                </div>
               </fieldset>
              </div>
              
                <br>
                <fieldset>
                  <div class="box2">
                    <label for="address">Company Email address:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.companyEmailAddress" placeholder="email@email.com">
                  </div>
                  <div class="box2">
                    <label for="address">Company Phone Number:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.businessPhoneNumber" @keypress="isNumber($event)">
                  </div>
                </fieldset>
                <fieldset>
                  <div class="box2">
                    <label for="address">Company website:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.companyWebsite">
                  </div>
                  <div class="box2">
                    <label for="address">Nature of Business:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.businessNature">
                  </div>

                </fieldset>

                <br>
                <fieldset>
                  <h5 class="legend">Established Date</h5>
                  <div class="box">
                  <p class="form-control text-dark">{{formatDate(init.corpDetails.corpInfo.dateEstablish)}}</p>
                  </div>
                </fieldset>

            
                <br>
                <fieldset>
                  <h5 class="legend">
                    Transaction volume details
                  </h5>
                  <div class="box">
                    <label for="address">Sales volume per month:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.salesPerMonth" @keypress="isNumber($event)">
                  </div>
                  <div class="box">
                    <label for="address">Estimated Txn per Month:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.txnPerMonth" @keypress="isNumber($event)">
                  </div>
                  <div class="box">
                    <label for="address">Gross income (Annual):<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.grossIncomeAnnual" @keypress="isNumber($event)">
                  </div>
                </fieldset>
              
                <br>

                <fieldset>
                  <h5 class="legend">
                    Company Bank Account Details
                  </h5>
                  <div class="box">
                    <label for="address"> Account Number:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.bankAccountNumber" @keypress="isNumber($event)">
                  </div>
                  <div class="box">
                    <label for="business"> Account Name:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.bankAccountName">
                  </div>
                  <div class="box">
                    <label for="business">Bank Name:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.bankName">
                  </div>

                </fieldset>
             <br>
              <hr>

              <fieldset>
                <h5 class="legend">Contact Person's Information</h5>
                <div class="box">
                  <label for="address">Contact Person's Full name:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.contactPerson">
                </div>
                <div class="box">
                  <label for="address">Email address:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.contactEmailAddress" placeholder="email@email.com">
                </div>
                <div class="box mobile-container">
                  <label for="address">Mobile Phone:<strong>*</strong></label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <button class="btn btn-flag" type="button"  >
                        <img class="default-flag" :src="require('@/assets/signup_flags/' + mcountry + '.png' )">
                        <span class="text-dark">{{ mobileCode }}</span>
                     
                      </button>
                    </div>
                    <input type="number" class="form-control" id="formGroupExampleInput" v-model="init.corpDetails.corpInfo.contactPhoneNumber" @keypress="isNumber($event)">
                  </div>
                  <div class="country-list" v-if="showCountryForContact">
                    <div class="row">
                      <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForContact(l)" >
                        <div>
                          <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                        </div>
                        <div class="country-name">
                          <span> {{ l.country }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              
              <hr>
              <br>
              <p class="text-white" style="color: red;">{{errorMsg}}</p>
              <button type="submit" class="btn btn-main" id="loginBtn">Next</button>
            </form>
            <!-- <button class="btn btn-cancel" id="loginBtn" v-on:click="goToHome">HOME</button> -->
          </div>

         
          

        </div>
      </div>
      <Upload v-if="showUpload" @close="openUpload"></Upload>
      <UploadSelfie v-if="showUploadselfie" @close="openUploadSelfie"></UploadSelfie>
      <UploadDocs v-if="showUploadDocs" @close="openUploadDocs"></UploadDocs>
      <view-id v-if="showDetails"  :poidItem="poidItem" :poidKey="poidKey"></view-id>
      
    </div>

  </div>
</template>

<script>
  import Upload from '@/components/modals/Upload.vue'
  import UploadDocs from '@/components/modals/UploadDocs.vue'
  import UploadSelfie from '@/components/modals/UploadSelfie.vue'
  import ViewId from '@/components/modals/ViewId.vue'
  import viewHeader from '@/views/viewHeader.vue'
  import moment from 'moment';

  export default {
    name: 'CorporateComponent',
    components: {
      UploadSelfie,
      Upload,
      UploadDocs,
      ViewId,
      viewHeader
    },
    computed: {
      show() {
        return this.$store.state.show;
      },
    },
    data: function() {
      return { 
          currentStep: 3,
          profile:{
            firstName:"",
            middleName:"",
            lastName:"",
            emailAddress:"",
            mobileNumber:""

        },
        isNumberValid: true,
        isDateValid: true,
        isEmail: false,
        isMobile: false,
        initData:"",
        birthdate:'',
        birthDate:{
              day: '',
              month: '',
              year: ''
            },
        
        formattedBirthdate: '',
        isUnderage: false,
       
        errorMsg:"",
        address:{
          numberStreet:"",
          cityTown:"",
          provState:"",
          zipcode:""

        },
        extended:{
          birthOfPlace: "",
          occupation:"",
          maidenName:"",
          natureOfWork:"",
          sourceOfFunds:"",
          selectedMaritalStatus:"",
          employer:"",
          idNumber:"",
          homePhone: '11111'
        },

        errors: {
          profile: {
            msg: "",
            show: false,
           
          }
        },
        mcountry:'GB',
        ncountry:'GB',
        mobileCountry:'GB',
        accountcountry:'GB',
        nnationality:'Philippines',
        showCountry: false,
        showCountryForMobile: false,
        showCountryForAddress: false,
        showCountryForContact: false,
        showCountryForNationality: false,
        showCountryForAccountAddress: false,
        selected: 'mobile',
        country: 'GB',
        mobileCode: '44',
        hideOrShow: "Show",
        days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
        list: {
          mobileCodes: []
        },
        isError: false,
        captchaString: "",
        showUpload: false,
        showUploadselfie: false,
        showUploadDocs: false,
        numberValue: '',
        hasTradingName: false,
       
        uploaded: false,
        poidDesc:'',
        approvalStatus:'',
        poid:[],
            poidItem:'',
            showDetails: false,
            poidData:'',
            poidKey:''
        
    
       
      }
    },
    computed:{
      
       init() {
        	return this.$store.state.init;
    	},
      
      show() {
        return this.$store.state.show;
      },

    },
    methods: {
      goBack(){
        window.location.href="/#/verification/"
      },
      updateDate() {
      if (this.day && this.month && this.year) {
        // Do any additional validation or formatting if needed
        const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
        console.log('Selected Date:', formattedDate);
      }
    },
     
          viewId(details){
            this.poidItem = details
            this.poidKey = details.poidKey
            console.log(details);
            
            this.showDetails = !this.showDetails
            
          },
      onChange(event){
        const hasTradingName = event.target.value;
            this.hasTradingName = hasTradingName

      },
      getPoidType(){
        const options = {
              method: 'GET',
              headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
              url: '/cx/poids',
            }

            this.axios(options)
                .then((response) => {               
               this.poid = response.data
                  if(this.poid != 0){
                    this.uploaded = true
                  }
                }).catch((err) => {
                console.log(err);

                })

      },
      formatDate: function(date) {
              return moment(date).format('MM/DD/yyyy');
            },
      getClass(item) {
      if (item.approvalStatus === 'APPROVED') {
        this.showUpload = false;
        this.showUploadDocs = false;
        this.showUploadselfie = false;

        if (item.poidType === 'validId' || item.poidType === 'poa' || item.poidType === 'selfie') {
          return 'green';
        }
      }

      return ''; // Return an empty string if no special class is required
    },
      
      
    
      openUpload(){
          this.showUpload = !this.showUpload
         
      },
      openUploadDocs(){
        this.showUploadDocs = !this.showUploadDocs
        if(this.poidType == 'poa'){
            this.showUploadDocs = false
          }
      },
      openUploadSelfie(){
        this.showUploadselfie = !this.showUploadselfie
        if(this.poidType == 'selfie'){
            this.showUploadselfie = false
          }
      },
      uploadDocs(){
        window.location.href = "/#/upload-document";
      },
      goToHome:function(){
        window.location.href = "/";
      },
     
    
		
      getMobileCodes: function() {
      this.axios.get('/assets/js/mobilecodes.json')
        .then((response) => {
          this.list.mobileCodes = response.data;
        }).catch((err) => {
          console.log(err);

        })
    },

     
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
     
      validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
     
   
     

      sessionCheck() {
       
          const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/sessioncheck',
                }
          this.axios(options)
          .then((response) => {
           this.init.hasSession = true;
           this.getProfile();
          }).catch((err) => {
            this.init.hasSession = false;
                
                // if(err.response.data.msgCode == '00001') {
                //   window.location.href = "/#/";
                // }  
  
               
  
              })
      },


      getProfile() {
        const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/profile',
                }
                this.axios(options)
                .then((response) => {
                this.init.profile = response.data;
                console.log(response.data)
                this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                  this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                  this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
                  

                if(response.data.presentAddress != null){
                    this.address.numberStreet = response.data.presentAddress.numberStreet
                    this.address.cityTown = response.data.presentAddress.cityTown
                    this.address.provState = response.data.presentAddress.provState
                    this.address.zipcode = response.data.presentAddress.postalCode
                }
                }).catch((err) => {
                    this.init.hasSession = false;
                })
            },
    },
    mounted() {
    
     this.getMobileCodes();
     this.getPoidType();
    },
    beforeMount(){
      this.sessionCheck();
    },
    watch: {
     
    }
  }
</script>
 
<style scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
.form-check{
  width: 1rem;
  height: 1rem;
}
  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    max-width: 1000px;
    min-width: 350px !important;
    margin: 10px auto;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    z-index: 2;
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}

.form-check{
  width: 3rem;
  
}
p{
  color: white;
  padding-right: 2em;
}
   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px !important;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    width: 250px;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 250px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>