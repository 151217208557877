<template>
  <div class="main-content">
    <img :src="require('../assets/images/bg1.jpg')" class="bg" />
   
    <div class="header-wrapper">
      <!-- <a href="/">
        <img class="logo" :src="require('@/assets/logo.png')" >
      </a>  -->
     
    </div> 
    <div class="wrapper">
      <div class="progress-bars">
        <div class="progress-bar" :class="{ active: currentStep >= 1 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 2 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 3 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 4 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 5 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 6 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 7 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 8 }"></div>
      </div>
      <h3 class="text-white d-flex justify-content-center"> SETUP CORPORATE ACCOUNT</h3>

    </div>
    
    <div class="content-wrapper">
     
      <div class="modal-body">
        <div class="alert alert-danger" v-if="errors.profile.show">
          {{ errors.profile.msg }}
      </div>
        <div>
          
          <div v-if="currentStep == 7">
              <button class="btn btn-card-upload" :class="{ 'green': poidType === 'certificate'}" >Certificate of Incorporation</button>
                <span>
                  <button class="btn-upload" @click="openUploadCoi" >UPLOAD</button>
                </span>

              <button class="btn btn-card-upload">Articles of Incorporation (AOI) and By-laws</button>
                <span>
                  <button class="btn-upload" @click="openUploadAoi">UPLOAD</button>
                </span>

              <button class="btn btn-card-upload">Latest General Information Sheet (GIS)</button>
                <span>
                  <button class="btn-upload" @click="openUploadGis">UPLOAD</button>
                </span>

              <button class="btn btn-card-upload">Anti-Money Laundering (AML) Questionnaire</button>
                <span>
                  <button class="btn-upload" @click="openUploadAml">UPLOAD</button>
                </span>
                <span>
                  <button class="btn-upload" @click="downloadAml">DOWNLOAD</button>
                </span>

              <button class="btn btn-card-upload">Ultimate Beneficial Owner (UBO) Declaration</button>
                <span>
                  <button class="btn-upload" @click="openUploadUbo">UPLOAD</button>
                </span>

              <button class="btn btn-card-upload">Bureau of Internal Revenue (BIR) Certificate of Registration</button>
                <span>
                  <button class="btn-upload" @click="openUploadBir">UPLOAD</button>
                </span>

              <button class="btn btn-card-upload">Bank Statement within 3 months with Bank address</button>
                <span>
                  <button class="btn-upload" @click="openUploadBank">UPLOAD</button>
                </span>

              <button class="btn btn-card-upload">Business Permit</button>
                <span>
                  <button class="btn-upload" @click="openUploadPermit">UPLOAD</button>
                </span>

              <button class="btn btn-card-upload">BSP Certificate (Any applicable BSP licences/secondary licenses)</button>
                <span>
                  <button class="btn-upload" @click="openUploadBsp">UPLOAD</button>
                </span>

              <button class="btn btn-card-upload">Duly-notarized Secretary's Certificate for authorized person</button>
                <span>
                  <button class="btn-upload" @click="openUploadSecCert">UPLOAD</button>
                </span>
                <span>
                  <button class="btn-upload" @click="downloadSecCert">DOWNLOAD</button>
                </span>

              <button class="btn btn-card-upload">Audited Financial Statement</button>
                <span>
                  <button class="btn-upload" @click="openUploadAfs">UPLOAD</button>
                </span>
              <button class="btn btn-card-upload">FATCA Form</button>
                  <span>
                    <button class="btn-upload" @click="openUploadFatca">UPLOAD</button>
                  </span>
                  <span>
                    <button class="btn-upload" @click="downloadFatca">DOWNLOAD</button>
                  </span>
                 
                
             
          </div>
          <div>
            <hr>
            <br>
            <div class="form-group">
              <button class="btn-upload" @click="openUpload">UPLOAD OTHER DOCUMENTS</button>
            </div>
           
            <hr>
            <br>
            <div v-if="poid.length > 0">
              <label>UPLOADED</label>
              <button class="btn btn-display"
                v-for="(item, index) in poid"
                :key="index"
                :class="getClass(item)"
                @click="viewId(item)"
              >
                {{ item.poidDesc }} 
              </button>

            </div>
           
           
          </div>

        </div>

        
      </div>
      <br>
      <hr>
      <button class="btn btn-main" id="loginBtn" v-on:click="goToBoard">Next</button>
      <!-- <button class="btn btn-upload" id="loginBtn" v-on:click="goToHome">Skip</button> -->
      <Coi v-if="showUploadCoi" @close="openUploadCoi"></Coi>
      <Aoi v-if="showUploadAoi" @close="openUploadAoi"></Aoi>
      <Afs v-if="showUploadAfs" @close="openUploadAfs"></Afs>
      <Aml v-if="showUploadAml" @close="openUploadAml"></Aml>
      <Bir v-if="showUploadBir" @close="openUploadBir"></Bir>
      <Bsp v-if="showUploadBsp" @close="openUploadBsp"></Bsp>
      <Bank v-if="showUploadBank" @close="openUploadBank"></Bank>
      <SecCert v-if="showUploadSecCert" @close="openUploadSecCert"></SecCert>
      <Ubo v-if="showUploadUbo" @close="openUploadUbo"></Ubo>
      <Permit v-if="showUploadPermit" @close="openUploadPermit"></Permit>
      <Fatca v-if="showUploadFatca" @close="openUploadFatca"></Fatca>
      <Gis v-if="showUploadGis" @close="openUploadGis"></Gis>
      <UploadDocs v-if="showUpload" @close="openUpload"></UploadDocs>
      <view-id v-if="showDetails"  :poidItem="poidItem" :poidKey="poidKey"></view-id>
      
      
    </div>

  </div>
</template>

<script>
  import Coi from '@/components/modals/Documents/Coi.vue'
  import Aoi from '@/components/modals/Documents/Aoi.vue'
  import Afs from '@/components/modals/Documents/Afs.vue'
  import Aml from '@/components/modals/Documents/Aml.vue'
  import Bir from '@/components/modals/Documents/Bir.vue'
  import Bsp from '@/components/modals/Documents/Bsp.vue'
  import Bank from '@/components/modals/Documents/Bank.vue'
  import SecCert from '@/components/modals/Documents/SecCert.vue'
  import Ubo from '@/components/modals/Documents/Ubo.vue'
  import Permit from '@/components/modals/Documents/Permit.vue'
  import Fatca from '@/components/modals/Documents/Fatca.vue'
  import Gis from '@/components/modals/Documents/Gis.vue'
  import ViewId from '@/components/modals/ViewId.vue'
  import UploadDocs from '@/components/modals/UploadDocs.vue'
 

  export default {
    name: 'UploadDocumentComponent',
    components: {
    
      Coi,
      Aoi,
      Afs,
      Aml,
      Bir,
      Bsp,
      Bank,
      SecCert,
      Ubo,
      Permit,
      Fatca,
      Gis,
      ViewId,
      UploadDocs
    


    },
    computed: {
      show() {
        return this.$store.state.show;
      },
    },
    data: function() {
      return { 
        state:'selectAcct',
        accountType: 'individual',
          currentStep: 7,
          profile:{
            firstName:"",
            middleName:"",
            lastName:"",
            emailAddress:"",
            mobileNumber:""

        },
        isNumberValid: true,
        isDateValid: true,
        isEmail: false,
        isMobile: false,
        initData:"",
        birthdate:'',
        birthDate:{
              day: '',
              month: '',
              year: ''
            },
        
        formattedBirthdate: '',
        isUnderage: false,
       
        errorMsg:"",
        address:{
          numberStreet:"",
          cityTown:"",
          provState:"",
          zipcode:""

        },
        extended:{
          birthOfPlace: "",
          occupation:"",
          maidenName:"",
          natureOfWork:"",
          sourceOfFunds:"",
          selectedMaritalStatus:"",
          employer:"",
          idNumber:"",
          homePhone: '11111'
        },

        errors: {
          profile: {
            msg: "",
            show: false,
           
          }
        },
        showCountry: false,
        selected: 'mobile',
        country: 'GB',
        mobileCode: '44',
        hideOrShow: "Show",
        list: {
          mobileCodes: []
        },
        isError: false,
        captchaString: "",

        showUploadCoi: false,
        showUploadAoi: false,
        showUploadGis: false,
        showUploadAml: false,
        showUploadUbo: false,
        showUploadBir: false,
        showUploadBank: false,
        showUploadPermit: false,
        showUploadBsp: false,
        showUploadSecCert: false,
        showUploadAfs: false,
        showUploadFatca: false,
        showUpload: false,
        poidType:[],
        approvalStatus:'',
        poid:[],
            poidItem:'',
            showDetails: false,
            poidData:'',
            poidKey:''
   

        
    
       
      }
    },
    computed:{
       init() {
        	return this.$store.state.init;
    	},
      years () {
				const year = new Date().getFullYear()
				return Array.from({length: year - 1900}, (value, index) => 1901 + index)
				},
      show() {
        return this.$store.state.show;
      },

    },
    methods: {
      viewId(details){
            this.poidItem = details
            this.poidKey = details.poidKey
            console.log(details);
            
            this.showDetails = !this.showDetails
            
          },
      getPoidType(){
        const options = {
              method: 'GET',
              headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
              url: '/cx/poids',
            }

            this.axios(options)
                .then((response) => {               
               this.poid = response.data
                  if(this.poid != 0){
                    this.uploaded = true
                  }
                }).catch((err) => {
                console.log(err);

                })

      },
      getClass(item) {
      if (item.approvalStatus === 'APPROVED') {
        if (item.poidDesc === 'validId' || item.poidDesc === 'poa' || item.poidDesc === 'selfie') {
          return 'green';
        }
      }

      return ''; // Return an empty string if no special class is required
    },
      openUploadCoi(){
        this.showUploadCoi = !this.showUploadCoi
      },
      openUploadAoi(){
        this.showUploadAoi = !this.showUploadAoi
      },
      openUploadGis(){
        this.showUploadGis = !this.showUploadGis
      },
      openUploadAml(){
        this.showUploadAml = !this.showUploadAml
      },
      openUploadUbo(){
        this.showUploadUbo = !this.showUploadUbo
      },
      openUploadBir(){
        this.showUploadBir = !this.showUploadBir
      },
      openUploadBank(){
        this.showUploadBank = !this.showUploadBank
      },
      openUploadPermit(){
        this.showUploadPermit = !this.showUploadPermit
      },
      openUploadBsp(){
        this.showUploadBsp = !this.showUploadBsp
      },
      openUploadSecCert(){
        this.showUploadSecCert = !this.showUploadSecCert
      },
      openUploadAfs(){
        this.showUploadAfs = !this.showUploadAfs
      },
      openUploadFatca(){
        this.showUploadFatca = !this.showUploadFatca
      },
      openUpload(){
        this.showUpload = !this.showUpload
      },
      downloadSecCert(){
        const pdfFilename = 'SecretaryCertificate.pdf';
        const publicPath = process.env.BASE_URL;
        const pdfUrl = publicPath + pdfFilename;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = pdfFilename;
        link.click();
      },

       downloadAml() {
        const pdfFilename = 'AML Questionnaire.pdf';
        const publicPath = process.env.BASE_URL;
        const pdfUrl = publicPath + pdfFilename;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = pdfFilename;
        link.click();
  
    },
    downloadFatca() {
        const pdfFilename = 'FATCA FORM.pdf';
        const publicPath = process.env.BASE_URL;
        const pdfUrl = publicPath + pdfFilename;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = pdfFilename;
        link.click();
  
    },
      

      
      goToHome:function(){
        window.location.href = "/";
      },
      goToBoard(){
        window.location.href = "/#/board-directors";
      },
    
      
     
      
      sessionCheck() {
        const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/sessioncheck',
                }
          this.axios(options)
          .then((response) => {
           this.init.hasSession = true;
           this.getProfile();
          }).catch((err) => {
            this.init.hasSession = false;
                
                // if(err.response.data.msgCode == '00001') {
                //   window.location.href = "/#/";
                // }  
  
               
  
              })
      },


      getProfile() {
        const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/profile',
                }
                this.axios(options)
                .then((response) => {
                this.show.profile = response.data;
                console.log(response.data)
                 this.show.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                  this.show.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                  this.show.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
                this.profile.firstName = response.data.firstName
                this.profile.middleName = response.data.middleName
                this.profile.lastName = response.data.lastName
                this.profile.emailAddress = response.data.emailAddress
                this.profile.mobileNumber = response.data.mobilePhone
                // this.day = response.data.birthDd
                // this.month = response.data.birthMm
                // this.year = response.data.birthYear
                this.extended.idNumber = response.data.identityNumber
                this.extended.placeOfBirth = response.data.placeOfBirth
                this.extended.maidenName = response.data.mothersMaidenName
                this.extended.occupation = response.data.occupation
                this.extended.employer = response.data.employer
                this.extended.natureOfWork = response.data.natureOfWork
                this.extended.sourceOfFunds = response.data.sourceOfFunds
                this.extended.selectedMaritalStatus = response.data.maritalStatus

                if(response.data.presentAddress != null){
                    this.address.numberStreet = response.data.presentAddress.numberStreet
                    this.address.cityTown = response.data.presentAddress.cityTown
                    this.address.provState = response.data.presentAddress.provState
                    this.address.zipcode = response.data.presentAddress.postalCode
                }
              
               
              

                
                }).catch((err) => {
                    this.init.hasSession = false;
                })
            },
    },
    mounted() {
    this.sessionCheck();
    this.getPoidType();
    
    },
    watch: {
     
    }
  }
</script>
 
<style scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }

  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    max-width: 1000px;
    min-width: 350px !important;
    margin: 10px auto;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    z-index: 2;
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}

.form-check{
  width: 3rem;
  
}
p{
  color: white;
  padding-right: 2em;
}
   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-upload{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px !important;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    width: 250px;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 250px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>