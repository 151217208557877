<template>
    <div class="backdrop">
      <div class="b-modal">
          <div class="modal-header text-right">
              <h5 class="mt-2 text-white">Upload additional documents</h5>
              <p @click="closeModal" class="x">x</p>
          </div>
        <div class="modalBody">
          <div class="" v-if="step == '0'" ><button @click.prevent="uploadNow()">Continue</button>
            <div class="form-group" style="color: white; padding: 5px; font-size: 1rem;" >
                <p>Take a photo of your documents. The photo should be:
                  <ol style="color: white; padding: 20px; font-size: 1rem;">
                    <li>
                       bright and clear
                    </li>
                    <li>
                       all corners of the document should be visible.
                    </li>
                  </ol>
                  <label>Valid placement</label>
                  <div class="wrapper" >
                    
                    <div class="product-grid" >
                    
                      <div  class="product-card">
                        <img :src="require('../../assets/id/doc-yes.png')"
                        class="product-image"
                        img-top>
                      </div>
                    </div>  
                  </div>
  
                  <label>Invalid placement</label>
                  <div class="wrapper" >
                    
                    <div class="product-grid" >
                    
                     
                      <div  class="product-card">
                        <img :src="require('../../assets/id/doc-n1.png')"
                        class="product-image"
                        img-top>
                        <img :src="require('../../assets/id/doc-n2.png')"
                        class="product-image"
                        img-top>
                      </div>
                    
                      
  
                    </div>  
                  </div>
                
                </p>
                
  
              </div>
          
     

          </div>
             <div v-if="step == '3'" class="mt-5">
                  <p class="text-white">Upload successful. You will be notified once it has been approved.</p>
                  <p>
                  <button class="btn btn-main submit" @click="done">Done</button>
                  </p>
              </div>
               <!--FAILED-->
            <div v-if="isFailed" class="failed">
              <h5 class="text-white">Upload failed. Try again</h5>
            </div>
              
  
           <form v-on:submit.prevent="uploadImage($event)" v-if="step == '1'">
               <label for="idType">Document Type:</label>
                     
                  <div class=" form-group">
                    <div class=" px-5">
                      <input type="text" class="form-control" v-model="idType"/>
                      </div>
                
                    <div class="buttonWrapper" >
                        <label > {{idType}} </label>
                        <div class="dropbox text-white">
                          <input type="file" ref="fileInput" @change="handleFileSelect">
                        </div>
                    </div>
                    <div>
                        <button type="submit" class="btn btn-main" id="saveBtn">Submit</button>
                   </div>
                  </div>
              
  
              
          </form>
                <form v-on:submit.prevent="uploadImageBack($event)" v-if="step == '2'">
               
                      <div class="buttonWrapper">
                         
                          
                          <label>{{idType}} (Back)</label>

                        <div class="dropbox text-white" >
                            <input type="file" ref="fileInput" @change="handleFileSelectBack">
                          </div>
 
                      </div>
                      <div>
                           <button type="submit" class="btn btn-main" id="saveBtn">Submit</button>
                      </div>
  
                  
  
                  </form>
           
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
      components: {
  
      },
      data: function() {
          return {
            uploadError:'',
              step:'0',
              idType:'',
              description:'accountHolder-proofOfAddress',
              selectedFile:'',
              selectedFileBack:'',
              idNumber:'111',
              uploadFieldName: 'imageFile',
              uploadFieldNameBack: 'imageFile',
              isSuccess: false,
              bothSuccess: false,
              isFailed: false,
              isLoading: false,
              response:'',
              errors: {
                  profile: {
                      show:false,
                      msg: ""
                  },
              },
              
          }
      },
  
      computed: {
       
      },
  
      mounted() {
       
      },
  
      watch: {
        
      },
  
      methods: {
        uploadNow(){
          this.step = '1'
        },
            handleFileSelect() {
            const file = this.$refs.fileInput.files[0];
            this.selectedFile = file ? file.name : '';
            },
            handleFileSelectBack() {
            const file = this.$refs.fileInput.files[0];
            this.selectedFileBack = file ? file.name : '';
            },

          closeModal(){
              this.$emit("close")
          },
  
          done(){
            this.$emit("close")
            window.location.reload();
          },
           uploadImage(event) {
              
             if(this.idType == "" || this.idType == null || this.idType == undefined)  {
              this.errors.profile.show = true;
              this.errors.profile.msg = "This is required";
              return false;
              }
              if(this.idNumber == "" || this.idNumber == null || this.idNumber == undefined)  {
              this.errors.profile.show = true;
              this.errors.profile.msg = "This is required";
              return false;
              } 

              const params = {
                poidImageFile: this.selectedFile,
                poidType: this.idType,
                poidDescription: this.description,
                poidNumber: this.idNumber,
                }
  
  
           let data = new FormData();
                      data.append('poidImageFile', this.$refs.fileInput.files[0]);
                      data.append('poidType', this.idType);
                      data.append('poidDescription',this.description); 
                      data.append('poidNumber', this.idNumber);
                      
  
              
              const options = {
                  method: 'POST',
                  headers: {
                        'content-type': 'image/png',
                        'blox-authz': localStorage.getItem("tokenId") 
                  },
                  data,
                  url: '/cx/uploadpoid',
              };
  
             this.axios(options).then(
                  response => {
                      console.log(response.request.status)
                      this.response = response.request.status
                      this.isLoading = true 
                      
                      if(this.response = 200){
                       
                          setTimeout( () => {
                              this.isLoading = false
                              this.bothSuccess=true 
                              this.step = '3'
                          
                              
                          },1000)
                      } else {
                          this.isFailed = true
                          setTimeout(()=>{
                          this.isFailed = false
                        },2000)
                      }
                  }
                ).catch((err) => {
                    if(this.response = 500){
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                        },2000)
                    }

            })
          },
          uploadImageBack(event) {
            
  
            const params = {
                poidImageFile: this.selectedFileBack,
                poidType: this.idType,
                poidDescription: this.description,
                poidNumber: this.idNumber,
                }
  
  
           let data = new FormData();
                      data.append('poidImageFile', this.$refs.fileInput.files[0]);
                      data.append('poidType', this.idType);
                      data.append('poidDescription',this.description); 
                      data.append('poidNumber', this.idNumber);
                      
  
              
              const options = {
                  method: 'POST',
                  headers: {
                        'content-type': 'image/png',
                        'blox-authz': localStorage.getItem("tokenId") 
                  },
                  data,
                  url: '/cx/uploadpoid',
              };
  
             this.axios(options).then(
                  response => {
                      console.log(response.request.status)
                      this.response = response.request.status
                      this.isLoading = true 
                      if(this.response = 200){
                          
                          setTimeout( () => {
                              this.isLoading = false
                              this.bothSuccess=true 
                              this.step = '3'
  
                          },3000)
  
                          
                      } else {
                          this.isFailed = true
                          setTimeout(()=>{
                          this.isFailed = false
                        },2000)
                      }
                      
  
  
                  }
              ).catch((err) => {
                    if(this.response = 500){
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                        },2000)
                    }

            })
          },
        
      }
  }
  </script>
  
  <style scoped>
    .backdrop {
        top: 0;
        right:0;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 98;
        background: rgba(0, 0, 0, 0.6);
    
    }
    .b-modal{
      max-width: 500px;
       min-width: 500px;
        height: auto;
        margin: 100px auto;
        background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
        border-radius: 10px;
        z-index: 2;
        padding-bottom: 50px;
        font-family: 'Light';
        width: 100%;
      }
    
    .modal-header {
        margin-right:0px;
        font-size:15px !important;
        display:flex;
        justify-content:space-between;
        font-family: 'Light';
    }
    
    .modal-title{
        margin: 0 auto;
        color:white;
    }
    
    .modalBody {
        width:100%;
        padding:10px;
        border-radius: 10px;
        font-family: 'Light';
        max-width: 1000px;
       min-width: 500px;
    }
    
    .buttonWrapper {
      margin: 0 auto !important;
      text-align: center;
        width:100%;
        font-family: 'Light';
    }
    
    .x {
        cursor:pointer;
    }
    label{
        color: white;
        font-family: 'Light';
        font-size: 17px;
    }
    .failed{
      background-color: transparent;
      color: red !important;
    }
     .form-group{
            width: 100%;
        }
    
        .btn-main{
            margin: 30px auto;
            width: 100%;
            font-family: 'Light';
        }
        .btn-main:hover{
            color: white;
            font-family: 'Light';
        }
     
    
    
        @media screen and (max-width: 1100px) {
       
          .form{
              display: flex;
            }
        }
       @media screen and (max-width: 800px) {
        .form{
              display: flex;
            }
            
      }
    
        @media screen and (max-width: 600px) {
          .form{
              display: block;
            }
            .btn-main{
            margin: 30px auto;
            width: 100px;
            font-family: 'Light';
        }
            
        }
    
        @media screen and (min-width: 260px) and (max-width: 420px) {
    
           .b-modal {
                width: 300px;
               
            }
            .btn-main{
            margin: 30px auto;
            width: 100px;
            font-family: 'Light';
        }
        }
    
        @media screen and (min-width: 480px) and (max-width: 580px) {
            .b-modal {
                    width: 405px;
            }
            .form{
              display: block;
            }
            .btn-main{
            margin: 30px auto;
            width: 100px;
            font-family: 'Light';
        }
    
           
            
        }
        .wrapper{
            display: flex;
             padding: 2em;
            justify-content: center !important;
            margin: 0 auto !important;
            width: 100%;
        }
       
          .product-grid {
            display: flex;
           
          }
    
        .product-card {
          background-color: #0E111E;
            box-shadow:0 0 30px rgba(0,0,0,0.3);
            border: 1px solid transparent;
            border-radius: 12px;
            width: 7rem;
            height: auto;
            text-align: center;
            margin: 10px !important;
            color: white;
            cursor: none;
            font-size: 10px;
        
        }
    
    
        .product-image {
          padding: 10px 0px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            margin: 0 auto;
            background-color: none !important;
          width: 100%;
          max-width: 80px;
          height: auto;
          margin-bottom: 10px;
        }
        .logo{
          width: 5rem;
          padding: 10px;
        }
    </style>