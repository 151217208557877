<template>
  <div class="main">
    <img :src="require('../assets/images/bg1.jpg')" class="bg" />
    
    <product-List-two></product-List-two>
    <router-view></router-view>
    
  </div>
</template>
<script>

import viewTrending from "../views/viewTrending";

import viewProductList from "../views/viewProductList";





export default {
  name: "Home",
  components: {
    
    viewTrending,
    "product-List-two": viewProductList,
   
  },
  methods:{
    redirectLogin: function(emailOrMobile,pin,host, authz) {
         

            const params = {
              loginHost:host,
              loginId: emailOrMobile,
              pin: pin,
            }

            const data = Object.keys(params)
              .map((key) => `${key}=${encodeURIComponent(params[key])}`)
              .join('&');


            const options = {
             method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded',
                 },
              data,
              url: '/cx/login/pin/redirect',
            };

            this.axios(options)
            .then((response) => {
              localStorage.setItem("tokenId", response.data.tokenId);
            
              localStorage.setItem("authz", authz);
              this.$router.push({name: 'Dashboard'})

               
            }).catch((err) => {
                this.errors.login.show = true;
                if (err.response.data) { 

                  if(err.response.data.fieldErrors) {

                    err.response.data.fieldErrors.forEach((error) => {

                      switch (error.field) {


                        default:
                          this.errors.login[error.field] = error.defaultMessage;
                        break;
                      }
                    });

                  }  else {

                    this.errors.login.msg = err.response.data.msgText;
                  }
                }
                setTimeout(()=>{
                      this.clearError()
                    },4000);
                 
          })
         },
         confirmEmail(utoken){
        const params ={
          utoken: this.$route.params.utoken
        }
      

        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');


      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data,
        url: '/cx/emailchange/confirm',
      };

      this.axios(options)
        .then((response) => {
         
          localStorage.setItem("utoken", response.data.utoken);
          this.$router.push({name: 'Dashboard'})

        }).catch((err) => {
          console.log(err)

        })

      },
      confirmMobile(utoken){
        const params ={
          utoken: this.$route.params.utoken
        }
      

        const data = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');


      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data,
        url: '/cx/mobilechange/confirm',
      };

      this.axios(options)
        .then((response) => {
         
          localStorage.setItem("utoken", response.data.utoken);
          this.$router.push({name: 'Dashboard'})

        }).catch((err) => {
          console.log(err)

        })

      },
  },
  mounted(){

    if(this.$route.params.authorization) {
        let decodedAuthz = window.atob(this.$route.params.authorization);
        let authzArr = decodedAuthz.split(':');
        let emailOrMobile = authzArr[0];
        let pin = authzArr[1];
        let host = authzArr[2];
        this.redirectLogin(emailOrMobile, pin, host, this.$route.params.authorization);

      }

      if(this.$route.params.utoken){
       this.confirmEmail(this.$route.params.utoken);
       this.confirmMobile(this.$route.params.utoken);
      }
   






  }
};
</script>
<style >
 .bg{
   width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
  }
  @media screen and (max-width: 768px) {
    .main{
      height: 100% !important;
      padding-bottom: 20px;
    }
  }
</style>
