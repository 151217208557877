<template>
  <div class="main-content">
    <img :src="require('../../assets/images/bg1.jpg')" class="bg" />
    <div class="header-wrapper">
      <!-- <a href="/">
        <img class="logo" :src="require('@/assets/logo.png')" >
      </a>  -->
    </div> 
    <div class="content-wrapper">

        <div >
          <div class="alert alert-danger" v-if="errors.profile.show">
            {{ errors.profile.msg }}
        </div>
            <a href="javascript" class="d-flex justify-content-center"><img class="logo" :src="require('@/assets/logo.png')" ></a> 
          <div>
            <div class="progress-bars">
              <div class="progress-bar" :class="{ active: currentStep >= 1 }"></div>
              <div class="progress-bar" :class="{ active: currentStep >= 2 }"></div>
            </div>
            <form v-on:submit.prevent="submitProfile" v-show="currentStep === 1" >
              <hr>
               <div class="modal-body">
                <div class="form-group" >
                  <label for="formGroupExampleInput">First Name:</label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="profile.firstName" >
                </div>
                <div class="form-group" >
                  <label for="formGroupExampleInput">Last Name:</label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="profile.lastName" >
                </div>
               </div>
                <div class="form-group modal-footer">
                  <button type="submit" class="btn btn-main" id="loginBtn">Submit Basic Info</button>
                </div>
            </form>
  
            <form v-show="currentStep === 2">
                <h5 class="text-white">Select Account Type</h5>
                <hr>
                <div class="modal-body">
                  <div  class="d-flex btn-group-card">
                    <button class="btn btn-card" @click="selectAccount('individual')">
                      <div>
                        <img :src="require('../../assets/images/individ.png')" class="icon"/>
                      </div>
                      Individual Account
                    </button>
                    <button class="btn btn-card" @click="selectAccount('corporate')">
                      <div>
                        <img :src="require('../../assets/images/corpo.png')" class="icon"/>
                      </div>
                      Corporate Account
                    </button>
    
                  </div>

                </div>
             
              </form>
          </div>
        </div>
        <Upload v-if="showUpload" @close="openUpload"></Upload>
        
      </div>

</div>
</template>

<script>
  import Upload from '@/components/modals/Upload.vue'

  export default {
    name: 'SetupProfile',
    components: {
    
      Upload
    },
    computed: {
      show() {
        return this.$store.state.show;
      },
    },
    data: function() {
      return { 
        currentStep: 1,
        profile:{
          firstName:"",
          middleName:"",
          lastName:"",
          emailAddress:"",
          mobileNumber:""

        },
        isEmail: false,
        isMobile: false,
        initData:"",
        birthdate:"",
        birthDate:{
              day: null,
              month: null,
              year: null
            },
        months: [
              {text: 'January', value: '01'}, 
              {text:'February',value: '02'},
              {text:'March',value: '03' },
              {text:'April',value: '04' },
              {text:'May', value: '05'},
              {text:'June', value: '06'},
              {text:'July', value: '07'},
              {text:'August',value: '08'},
              {text:'September', value: '09'},
              {text:'October', value: '10'},
              {text:'November', value: '11'},
              {text:'December', value: '12'}
              ],
        days: [
              {text: '1', value: '01'}, 
              {text:'2',value: '02'},
              {text: '3', value: '03'}, 
              {text:'4',value: '04'},
              {text: '5', value: '05'}, 
              {text:'6',value: '06'},
              {text: '7', value: '07'}, 
              {text:'8',value: '08'},
              {text: '9', value: '09'}, 
              {text:'10',value: '10'},
              {text: '11', value: '11'}, 
              {text:'12',value: '12'},
              {text: '13', value: '13'}, 
              {text:'14',value: '14'},
              {text: '15', value: '15'}, 
              {text:'16',value: '16'},
              {text: '17', value: '17'}, 
              {text:'18',value: '18'},
              {text: '19', value: '19'}, 
              {text:'20',value: '20'},
              {text: '21', value: '21'}, 
              {text:'22',value: '22'},
              {text: '23', value: '23'}, 
              {text:'24',value: '24'},
              {text:'25',value: '25'},
              {text: '26', value: '26'}, 
              {text:'27',value: '27'},
              {text: '28', value: '28'}, 
              {text:'29',value: '29'},
              {text: '30', value: '30'}, 
              {text:'31',value: '31'},
              ],
        formattedBirthdate: '',
        isUnderage: false,
        month:"",
        day:"",
        year:"",
        errorMsg:"",
        address:{
          numberStreet:"",
          cityTown:"",
          provState:"",
          zipcode:""

        },
        extended:{
          birthOfPlace: "",
          occupation:"",
          maidenName:"",
          natureOfWork:"",
          sourceOfFunds:"",
          selectedMaritalStatus:"",
          employer:"",
          idNumber:"",
          homePhone: '11111'
        },

        errors: {
          profile: {
            msg: "",
            show: false,
           
          }
        },
        showCountry: false,
        selected: 'mobile',
        country: 'GB',
        mobileCode: '44',
        hideOrShow: "Show",
        list: {
          mobileCodes: []
        },
        isError: false,
        captchaString: "",
        showUpload: false,
    
       
      }
    },
    computed:{
       init() {
        	return this.$store.state.init;
    	},
      years () {
				const year = new Date().getFullYear()
				return Array.from({length: year - 1900}, (value, index) => 1901 + index)
				}

    },
    
    methods: {
      selectAccount(accountType){
        this.accountType = accountType
        console.log(accountType)
        if(accountType == 'individual'){
          this.$router.push({
              name: 'Individual',
              params: {
                step: 3
              }
            });
        }
        if (accountType == 'corporate'){
          this.$router.push({
              name: 'CorporateSetup',
              params: {
                step: 3
              }
            });
          // window.location.href = "/#/corporate-setup-profile";
        }
      },
      openUpload(){
                this.showUpload = !this.showUpload
      },
      goToHome:function(){
        window.location.href = "/#/store";
      },
     
      validateBirthdate() {
        const date = new Date(this.birthdate);
        const today = new Date();
        const age = today.getFullYear() - date.getFullYear();
        const monthDiff = today.getMonth() - date.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
          age--;
        }
        this.isUnderage = age < 18;
        console.log(this.isUnderage)
        if (!this.isUnderage) {
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const year = date.getFullYear();
          console.log(day)
          this.month = month < 10 ? '0' + month : month.toString();
          this.day = day < 10 ? '0' + day : day.toString();
          this.year = year.toString();
        }
        if(this.isUnderage == true){
          this.errorMsg = "Please make sure you are 18 years old and above."
          setTimeout(()=>{
            this.errorMsg = ""
          }, 4000)
        }
       // console.log(this.month)
      },

      getInitialSetup(){
				this.axios.get('/cx/initialsetupdata', {
					headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
				})
						.then((response) => { 
							this.profile.mobileNumber = response.data.mobilePhone;
							this.profile.emailAddress = response.data.emailAddress;
							if(this.profile.mobileNumber){
								this.isMobile = true
								this.initData = 'mobile';
							}
							if(this.profile.emailAddress){
								this.isEmail = true;
								this.initData = 'email';
							}
						
						}).catch((err) => {
						//	console.log(err)

						})
			},
      toggleCountryList: function(){
          this.showCountry = !this.showCountry;
        
        },
       
      selectCountry: function(c) {
				this.showCountry = false;
				this.country = c.countryCode;
				this.mobileCode = c.mobileCode;
			},
		
      getMobileCodes: function() {
        this.axios.get('/assets/mobilecodes.json')
          .then((response) => {  
              this.list.mobileCodes = response.data; 
          }).catch((err) => {
             // console.log(err);

          })
      },

      filterInput(){
        this.profile.firstName = this.profile.firstName.replace(/[^A-Za-z]/g, '');
        this.profile.lastName = this.profile.lastName.replace(/[^A-Za-z]/g, '');
        this.profile.middleName = this.profile.middleName.replace(/[^A-Za-z]/g, '');
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      submitProfile(){
        if(this.profile.firstName == "" || this.profile.firstName == null || this.profile.firstName == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "First name is required";
            setTimeout(()=>{
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
            }, 3000)
            return false;
            }

            if(this.profile.lastName == "" || this.profile.lastName == null || this.profile.lastName == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Last name is required";
            setTimeout(()=>{
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
            }, 3000)
            return false;
            } 


            this.errors.profile.show = false;
            const params = {
            firstName: this.profile.firstName,
            lastName: this.profile.lastName
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');


            const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'blox-authz': localStorage.getItem("tokenId") 
            }, 
            data,
            url: '/cx/submitname',
            };

            this.axios(options)
            .then((response) => {
            //console.log(response.data)
            this.currentStep = 2
               
            }).catch((err) => {
             // console.log(err.response.data)     

                if(err.response.data.fieldErrors){
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage

                }
                 if(err.response.data.msgCode == '00001') {
                  window.location.href = "/#/";
                }  

                if(err.response.data.msgCode == '00020') {

                  setTimeout( () => {
                  window.location.href = "/#/setup-profile";
                },1000)
                  
              }  
            })
        
      },
   
     

      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
          .then((response) => { 
            this.getProfile();
             
            }).catch((err) => {
              

            })
        },


      getProfile() {
        const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/profile/',
          };

          this.axios(options)
                .then((response) => {
                this.init.profile = response.data;
                console.log(response.data)
                this.profile.firstName = response.data.firstName
                this.profile.middleName = response.data.middleName
                this.profile.lastName = response.data.lastName
                this.profile.emailAddress = response.data.emailAddress
                this.profile.mobileNumber = response.data.mobilePhone
                // this.day = response.data.birthDd
                // this.month = response.data.birthMm
                // this.year = response.data.birthYear
                this.extended.idNumber = response.data.identityNumber
                this.extended.placeOfBirth = response.data.placeOfBirth
                this.extended.maidenName = response.data.mothersMaidenName
                this.extended.occupation = response.data.occupation
                this.extended.employer = response.data.employer
                this.extended.natureOfWork = response.data.natureOfWork
                this.extended.sourceOfFunds = response.data.sourceOfFunds
                this.extended.selectedMaritalStatus = response.data.maritalStatus

                if(response.data.presentAddress != null){
                    this.address.numberStreet = response.data.presentAddress.numberStreet
                    this.address.cityTown = response.data.presentAddress.cityTown
                    this.address.provState = response.data.presentAddress.provState
                    this.address.zipcode = response.data.presentAddress.postalCode
                }
                
                }).catch((err) => {
                   
                })
            },
    },
    mounted() {
      this.checkSession();
     this.getMobileCodes();
     this.getInitialSetup();
    },
    watch: {
     
    }
  }
</script>
 
<style scoped>
 * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    }
  .header-wrapper img{
    width: 10em;
  }
  .logo{
    width: 10rem;
    padding: 2rem;
  }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly !important;
    width: 550px;
    height: auto;
    margin: 100px auto;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    z-index: 2;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
    padding: 5px;
  }

  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.form-group .button{
  margin-top: 100px !important;
}
.icon{
  width: 2rem;
}

.progress-bar {
  width: 100px;
  height: 10px;
  background-color: lightgray;
  margin-right: 5px;
  border-radius: 6px;
}

.active {
  background-color: #695536;
}
.enhanced{
  margin-top: 2em;
}

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}


   .btn-flag {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
  font-weight: bold;
  padding-top: 10px;
}
h1 {
text-align: center;
font-size: 5rem;
}

form {
width: 90%;
margin: 0 auto;
}

.box {
padding: 0.1rem;
border-radius: 6px !important;
margin-bottom: 10px;
}

label {
display: flex;
font-size: 16px;;
}

input, .form-control {
display: inline-block;
width: 100%;
border-radius: 10px !important;
}

input:required:invalid, input:focus:invalid {
background-position: right top;
background-repeat: no-repeat;
-moz-box-shadow: none;
}

input:required:valid {
background-position: right top;
background-repeat: no-repeat;
}

#masthead {
padding: 10rem 0;
background-size: 100%;
/*color: #fff;*/;
}
.help{
  margin: 2rem;
  
}
@media all and (min-width: 35rem) and (max-width: 60rem) {

.box {
float: left;
width: 50%;
}

label {
display: flex;
}

#masthead {
}
}

@media all and (min-width:60rem) {
label {
display: inline;
width: 150px;
float: left;
}

.box {
width: 50%;
float: left;
}

input {
width: 50%;
}

#masthead {
padding: 9rem;
}
}

</style>