import Vue from 'vue';
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex, axios);

export const store = new Vuex.Store({
  namespaced: true,
  state: {
    
    cart:{},
    cartItems:{},
    checkout: {},
    user: {
      firstName: '',
      lastName: '',
      fullName:''
    },
    
    init: { 
      currentLocation:'GB',
      profile:{},
      corpDetails:{
        corpInfo:{},
        corpOfficeAddress:{},
        tradingAddress:{},
      },
      user: {
        firstName: '',
        lastName: ''
      },
      hasSession: false,
      productName:"",
      price:"",
      description:"",
      symbol:"",
      icon:"",
      assets: [],
      asset: '',
      address: {},
      profile: {},
      history: {
        fromDate: '',
        toDate: null,
      },
      search:'',
      rowsPerPage: 10,
      page: 1,
      status: 'PENDING',
      userId:'',
      error:{
        msg:''
      }
    },
    

  },
  mutations: {
   
     //setupProfile//
     SETUP(state, payload){
      state.user = payload;
    },
    //cart.vue
    INIT_CART(state, payload) {
      state.cart = payload;
    },
    //getCartItems
    SET_CART(state, payload){
      state.cartItems = payload;
    },
    //checkout//
    CHECK_OUT(state, payload) {
      state.checkout = payload;
    },
   
  },
  actions: {
    //setupProfile
   submitName({commit}, userId, firstName, lastName) {
    commit("SETUP", {userId, firstName, lastName})
   },
   //productList
  

  

    //addToCart
    addToCart({commit}, userId, product, value, firstName, lastName) {
      commit("INIT_CART", {userId, product, value, firstName, lastName})
    },

    //getCartItems
    getCartItems({commit}, {userId, product, description, lockedPrice, amountToBuy, amountToPay, totalAmountDue}
      )
      { commit("SET_CART" , { userId, product, description, lockedPrice, amountToBuy, amountToPay, totalAmountDue}
      )
    },
    //checkout//
    checkoutDetails(
      { commit },
      { walletAddress, comment, payment, timestamp, trxId, terms,referenceId,
        amountBought,
        currency,
        currencyPrice,
        totalAmountPaid,
        paymentStatus, }
    ) {
      commit("CHECK_OUT", {
        walletAddress,
        comment,
        payment,
        timestamp,
        trxId,
        terms,
        referenceId,
        amountBought,
        currency,
        currencyPrice,
        totalAmountPaid,
        paymentStatus,
      });
    },
  },
  modules: {
  }
 
});