<template>
    <div class="backdrop">
      <div class="b-modal">
          <div class="modal-header text-right">
              <h5 class="mt-2 text-white">Upload Bank Statement within 3 months with Bank address</h5>
              <p @click="closeModal" class="x">x</p>
          </div>
        <div class="modalBody">
        
             <div v-if="step == '3'" class="mt-5">
                  <p class="text-white">Upload successful. You will be notified once it has been approved.</p>
                  <p>
                  <button class="btn btn-main submit" @click="done">Done</button>
                  </p>
              </div>
              
  
           <form v-on:submit.prevent="uploadImage($event)" v-if="step == '1'">
               <label for="idType">Document Type:</label>
                     
                  <div class="payment-container text-left d-block">
                  <form class="d-flex justify-content-between">
              
                    <div class="form-check px-5">
                        <input
                          class="form-check-input"
                          v-model="idType"
                          type="radio"
                          value="bank"
                          selected
                        />
                        <label class="form-check-label" for="poa">
                          Bank Statement within 3 months with Bank address
                        </label>
                      </div>
                    

                    
                  </form>
                  </div>
                      
  
            
              <div class="buttonWrapper" >
                
                 
                  <label > {{idType}} </label>
                  <div class="dropbox text-white">
                    <input type="file" ref="fileInput" @change="handleFileSelect">
                    <p class="text-white">Selected file: {{ selectedFile }}</p>
                  </div>
              </div>
              <div>
                  <button type="submit" class="btn btn-main" id="saveBtn">Submit</button>
              </div>
              
  
              
          </form>
             <form v-on:submit.prevent="uploadImageBack($event)" v-if="step == '2'">
               
                      <div class="buttonWrapper">
                         
                          
                          <label>{{idType}} (Back)</label>

                        <div class="dropbox text-white" >
                            <input type="file" ref="fileInput" @change="handleFileSelectBack">
                            <p class="text-white">Selected file: {{ selectedFileBack }}</p>
                          </div>
 
                      </div>
                      <div>
                           <button type="submit" class="btn btn-main" id="saveBtn">Submit</button>
                      </div>
  
                  
  
                  </form>
           
         
            <!--FAILED-->
            <div v-if="isFailed">
              <h2 class="red">Upload failed.</h2>
             
              <p  class="red">
                <a href="javascript:void(0)" @click="closeModal()">Try again</a>
              </p>
            </div>
            <div class="text-center">
              <img class="iconImg" >
           </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
      components: {
  
      },
      data: function() {
          return {
            uploadError:'',
              step:'1',
              idType:'bank',
              description:'bankStatement',
              selectedFile:'',
              selectedFileBack:'',
              idNumber:'111',
              uploadFieldName: 'imageFile',
              uploadFieldNameBack: 'imageFile',
              isSuccess: false,
              bothSuccess: false,
              isFailed: false,
              isLoading: false,
              response:'',
              errors: {
                  profile: {
                      show:false,
                      msg: ""
                  },
              },
              
          }
      },
  
      computed: {
       
      },
  
      mounted() {
       
      },
  
      watch: {
        
      },
  
      methods: {
        uploadNow(){
          this.step = '1'
        },
            handleFileSelect() {
            const file = this.$refs.fileInput.files[0];
            this.selectedFile = file ? file.name : '';
            },
            handleFileSelectBack() {
            const file = this.$refs.fileInput.files[0];
            this.selectedFileBack = file ? file.name : '';
            },

          closeModal(){
              this.$emit("close")
              window.location.reload();
          },
  
          done(){
            this.$emit("close")
            window.location.reload();
          },
           uploadImage(event) {
              
             if(this.idType == "" || this.idType == null || this.idType == undefined)  {
              this.errors.profile.show = true;
              this.errors.profile.msg = "This is required";
              return false;
              }
              if(this.idNumber == "" || this.idNumber == null || this.idNumber == undefined)  {
              this.errors.profile.show = true;
              this.errors.profile.msg = "This is required";
              return false;
              } 

              const params = {
                poidImageFile: this.selectedFile,
                poidType: this.idType,
                poidDescription: this.description,
                poidNumber: this.idNumber,
                }
  
  
           let data = new FormData();
                      data.append('poidImageFile', this.$refs.fileInput.files[0]);
                      data.append('poidType', this.idType);
                      data.append('poidDescription',this.description); 
                      data.append('poidNumber', this.idNumber);
                      
  
              
              const options = {
                  method: 'POST',
                  headers: {
                        'content-type': 'image/png',
                        'blox-authz': localStorage.getItem("tokenId") 
                  },
                  data,
                  url: '/cx/uploadpoid',
              };
  
             this.axios(options).then(
                  response => {
                      console.log(response.request.status)
                      this.response = response.request.status
                      this.isLoading = true 
                      
                      if(this.response = 200){
                       
                          setTimeout( () => {
                              this.isLoading = false
                              this.bothSuccess=true 
                              this.step = '3'
                          
                              
                          },1000)
                      } else {
                          this.isFailed = true
                          setTimeout(()=>{
                          this.isFailed = false
                        },2000)
                      }
                  }
                ).catch((err) => {
                    if(this.response = 500){
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                        },2000)
                    }

            })
          },
          uploadImageBack(event) {
            
  
            const params = {
                poidImageFile: this.selectedFileBack,
                poidType: this.idType,
                poidDescription: this.description,
                poidNumber: this.idNumber,
                }
  
  
           let data = new FormData();
                      data.append('poidImageFile', this.$refs.fileInput.files[0]);
                      data.append('poidType', this.idType);
                      data.append('poidDescription',this.description); 
                      data.append('poidNumber', this.idNumber);
                      
  
              
              const options = {
                  method: 'POST',
                  headers: {
                        'content-type': 'image/png',
                        'blox-authz': localStorage.getItem("tokenId") 
                  },
                  data,
                  url: '/cx/uploadpoid',
              };
  
             this.axios(options).then(
                  response => {
                      console.log(response.request.status)
                      this.response = response.request.status
                      this.isLoading = true 
                      if(this.response = 200){
                          
                          setTimeout( () => {
                              this.isLoading = false
                              this.bothSuccess=true 
                              this.step = '3'
  
                          },3000)
  
                          
                      } else {
                          this.isFailed = true
                          setTimeout(()=>{
                          this.isFailed = false
                        },2000)
                      }
                      
  
  
                  }
              ).catch((err) => {
                    if(this.response = 500){
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                        },2000)
                    }

            })
          },
        
      }
  }
  </script>
  
  <style scoped>
    .backdrop {
        top: 0;
        right:0;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 98;
        background: rgba(0, 0, 0, 0.6);
    
    }
    .b-modal {
        width: 620px;
        height: auto;
        margin: 50px auto;
        background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
        border-radius: 20px;
        border-color:white;
        border-width:2px;
        z-index: 99;
        overflow-x: hidden !important;
        color:black;
        max-height: calc(100vh - 110px);
        overflow-y: auto;
    }
    
    .modal-header {
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
        margin-right:0px;
        font-size:25px;
        display:flex;
        justify-content:space-between;
    }
    
    .modal-title{
        margin: 0 auto;
        color:white;
    }
    
    .modalBody {
      background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
        width:100%;
        padding:50px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
    }
    
    .buttonWrapper {
        width:100%;
        margin-top: 10px;
        padding-top: 15px;
    }
    
    .x {
        cursor:pointer;
    }
    .red{
        color: red;
    }
  
   
    
    
        @media screen and (max-width: 1100px) {
        
              
        }
       @media screen and (max-width: 800px) {
         
      }
    
        @media screen and (max-width: 600px) {
           
            
        }
    
        @media screen and (min-width: 280px) and (max-width: 420px) {
    
           .b-modal {
                width: 350px;
                height: auto;
                margin-top: 50px;
                background: rgb(13, 2, 57);
                border-radius: 20px;
                border-color:white;
                border-width:2px;
                z-index: 1;
            }
    
            .modalBody {
              background: rgb(13, 2, 57);
                width:100%;
                padding: 20px 10px;
                border-radius: 10px;
            }
            
    
      
          
    
    
        }
    
        @media screen and (min-width: 480px) and (max-width: 580px) {
            .b-modal {
                    width: 405px;
            }
    
        
         
        }
        .wrapper{
          display: flex;
           padding: 2em;
          justify-content: center !important;
          margin: 0 auto !important;
      }
     
        .product-grid {
          display: flex;
         
        }
  
      .product-card {
          background-image: linear-gradient(
                  180deg,
                #10184d 29%,
                #00103d 59%,
                  #010c2a 88%
          );
          box-shadow:0 0 30px rgba(0,0,0,0.3);
          border: 1px solid transparent;
          border-radius: 12px;
          width: 7rem;
          height: auto;
          text-align: center;
          margin: 10px !important;
          color: white;
          cursor: none;
      
      }
  
  
      .product-image {
        padding: 10px 0px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          margin: 0 auto;
          background-color: none !important;
        width: 100%;
        max-width: 80px;
        height: auto;
        margin-bottom: 10px;
      }
    </style>