import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Failed from "../components/modals/Failed.vue";
import Dashboard from "../components/Dashboard.vue";
import CreateProduct from "../components/CreateProduct.vue";
import NotFound from '../components/NotFound.vue';
import Individual from '../components/Individual.vue';
import SetupProfile from '../components/modals/SetupProfile.vue';
import Login from '../components/modals/Login.vue';
import Signup from '../components/modals/Signup.vue';
import CorporateSetup from '../components/CorporateSetup.vue';
import Corporate from '../components/Corporate.vue';
import Verification from '../components/Verification.vue';
import UploadDocument from '../components/UploadDocument.vue';
import EmailActivation from "../views/EmailActivation.vue";
import MobileActivation from "../views/MobileActivation.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // props: true,
  },
  // {
  //   path: "/store",
  //   name: "Dashboard",
  //   component: Dashboard,
  //   props: true,
  // },
  {
    path: "/:countryCode/store",
    name: "Dashboard",
    component: Dashboard,
    props: true,
    },
  {
    path: "/:countryCode/login",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/:countryCode/signup",
    name: "Signup",
    component: Signup,
    props: true,
  },
  {
    path: '/individual-setup-profile/:step',
    name: "Individual",
    component: Individual,
    props: true
  },
  {
    path: '/verification/',
    name: "Verification",
    component: Verification,
    props: true
  },
  {
    path: '/upload-document/',
    name: "UploadDocument",
    component: UploadDocument,
    props: true
  },
  
  
  
  {
    path: '/authz/:authorization',
    name: 'Authz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "home" */ '@/views/Home')
    },
    props: true,
  },
  {
    path: "/confirmemail/:utoken",
    name: "EmailActivation",
    component: EmailActivation,
    props: true,
  },
  {
    path: "/confirmmphone/:utoken",
    name: "MobileActivation",
    component: MobileActivation,
    props: true,
  },
  {
    path: "/createProduct",
    name: "CreateProduct",
    component: CreateProduct,
  },
  {
    path: "/failed/:txnId",
    name: "Failed",
    component: Failed
  },
  {
    path: "/setup-profile/",
    name: "SetupProfile",
    component: SetupProfile,
    props: true,
  },
  {
    path: "/corporate-setup-profile/:step",
    name: "CorporateSetup",
    component: CorporateSetup,
    props: true,
  },
  {
    path: "/corporate/:step",
    name: "Corporate",
    component: Corporate,
    props: true,
  },

  
    {
      path: "/cart/",
      name: "Cart",
      component: () => import(/* webpackChunkName: "cart" */ "../components/Cart.vue"),
      props: true,
    },
    {
      path: "/checkout/",
      name: "Checkout",
      component: () =>
        import(/* webpackChunkName: "checkout" */ "../components/Checkout.vue"),
      props: true,
    },
    {
      path: "/banking/:txnId",
      name: "Bank",
      component: () =>
        import(/* webpackChunkName: "checkout" */ "../components/modals/Bank.vue"),
      props: true,
    },
    {
      path: "/successful/:txnId",
      name: "Successful",
      component: () =>
        import(/* webpackChunkName: "successful" */ "../components/Successful.vue"),
      props: true,
    },
    {
      path: "/pending/:txnId",
      name: "Pending",
      component: () =>
        import(/* webpackChunkName: "pending" */ "../components/modals/Pending.vue"),
      props: true,
    },
    {
      path: "/txhistory/",
      name: "TransactionHistory",
      component: () =>
        import(/* webpackChunkName: "txhistory" */ "../views/TransactionHistory.vue"),
      props: true,
    },
    {
      path: "/complete-kyc/",
      name: "Kyc",
      component: () =>
        import(/* webpackChunkName: "successful" */ "../components/modals/Kyc.vue"),
      props: true,
    },
    {
      path: "/account-holder/",
      name: "Account",
      component: () =>
        import(/* webpackChunkName: "account-holder" */ "../components/modals/Account.vue"),
      props: true,
    },
    {
      path: "/board-directors/",
      name: "BoardOfDirectors",
      component: () =>
        import(/* webpackChunkName: "board-directors" */ "../components/BoardOfDirectors.vue"),
      props: true,
    },
    {
      path: '*',
      component: NotFound
    }


];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;