<template>
  <div class="container pt-5 col-10">

    <div class="d-flex justify-content-between">
      <div class="navbar-brand">
        <router-link :to="{ name: 'Home' }">
          <img src="@/assets/images/logo.png"
        /></router-link>
      </div>
     <div class="col-8">
        <b-input-group size="sm" class="mb-2 col-10">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            class="search-wrapper text-white"
            type="search"
            placeholder="Search crypto"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="container pt-5 mt-5">
      <h1 class="col-8 title">The crypto exchange you can trust</h1>
     
    </div>
  </div>
</template>

<script>



export default {
  props: ["currency", "isCryptoActive", "isTokenActive"],
  name: "viewTrending",
  components:{

  },
  data(){
    return{
      search:'',
      showCartModal: false,
      timerCount: 60,
      
      
    }

  },
  computed:{
    
  },
  mounted() {
    
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: false,
    },
  },
  methods:{
   cartModal() {
      this.$router.push('/cart/' )
    },
  }
};
</script>

<style scoped>
.form-select{
  width: 10%;
}
</style>
