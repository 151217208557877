<template>
  <div class="main-content">
    <img :src="require('../assets/images/bg1.jpg')" class="bg" />
    <div class="header-wrapper">
      <a href="/">
        <img class="logo" :src="require('@/assets/logo.png')" >
      </a> 
      
    </div> 
    <div class="wrapper">
      <div class="progress-bars">
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 1 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 2 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 3 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 4 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 5 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 6 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 7 }"></div>
        <div class="progress-bar" :class="{ active: this.$route.params.step >= 8 }"></div>
      </div>
      <h3 class="text-white d-flex justify-content-center"> SETUP CORPORATE ACCOUNT</h3>

    </div>
    
    <div class="content-wrapper">
      <div class="modal-body">
        <div class="alert alert-danger"  v-if="errors.profile.show">
          {{ errors.profile.msg }}
      </div>
        <div>
         

       
          

          <div v-if="this.$route.params.step == 3">
            <form v-on:submit.prevent="submitBusinessInfo" >
              <br>
              <h5 class="text-white">Business Information</h5>
               <fieldset>
                <div class="box2">
                  <label for="bType">Business Type:<strong>*</strong></label>
                  <select id="bType" v-model="business.type" class="form-control form-select" >
                    <option value="" disabled>Select business type</option>
                    <option value="PRIVATE">Private Company</option>
                    <option value="SOLE">Sole Proprietor</option>
                    <option value="PARTNER">Partnereship</option>
                    <option value="TRUST">Trust</option>
                    <option value="ASSOC">Association</option>
                  </select>
                  </div>
                <div class="box2" >
                  <label for="formGroupExampleInput"> Business Name:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.name" placeholder="Registered Business name">
                </div>
               </fieldset>
               <fieldset>
                <div class="box" >
                  <label for="formGroupExampleInput"> Business Number:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.number" placeholder="Registered Business number">
                </div>
                <div class="box">
                  <label for="bType">Issuing Agency:<strong>*</strong></label>
                  <select id="bType" v-model="business.issuingAgency" class="form-control form-select">
                    <option value="" disabled>Select Gov't Agency</option>
                    <option value="BIR">BIR</option>
                    <option value="DTI">DTI</option>
                    <option value="SEC">SEC</option>
                    <option value="IC">IC</option>
                    <option value="OTHER">Others</option>
                  </select>
                  </div>
                  <div class="box mobile-container">
                    <label for="loginId">Country of Issuance:<strong>*</strong></label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.countryIssuance" v-on:click="toggleCountryList" placeholder="select country of issuance"> 
                    </div>
                    <div class="country-list" v-if="showCountry">
                      <div class="row">
                        <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)" >
                          <div>
                            <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                          </div>
                          <div class="country-name">
                            <span> {{ l.country }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               </fieldset>
                  <br/>
                  <h5 class="legend">Registered Business Address</h5>
                <fieldset>
                      <div class="box2">
                        <label for="address">Address 1:<strong>*</strong></label>
                        <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.officeAddress.streetNumber">
                      </div>
                     
                      <div class="box2">
                        <label for="business">City:<strong>*</strong></label>
                        <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.officeAddress.cityTown">
                      </div>
                </fieldset>
                <fieldset>
                  <div class="box">
                    <label for="business">Province:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.officeAddress.provState">
                  </div>
                  <div class="box">
                    <label for="business">Zipcode:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.officeAddress.zipcode">
                  </div>
               
               
                  <div class=" box mobile-container">
                    <label for="business">Country:<strong>*</strong></label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="business.officeAddress.country" v-on:click="toggleCountryListforAddress">
                    </div>
                    <div class="country-list" v-if="showCountryForAddress">
                      <div class="row">
                        <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForAddress(l)" >
                          <div>
                            <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                          </div>
                          <div class="country-name">
                            <span> {{ l.country }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </fieldset>
                      
                  <div class="form-group ">
                    <p class="text-white" style="color: red;">{{errorMsg}}</p>
                    <button type="submit" class="btn btn-main" id="loginBtn" :class="{disabled : isSubmitting}">Submit Business Information</button>
                  </div>
            </form>
          </div>
         
       
          <div v-if="this.$route.params.step == 4">

            <form v-on:submit.prevent="submitExtendedBusiness"  class="enhanced">
              <br>
              <h5 class="text-white">Company Information</h5>
              <fieldset>
                  <label for="address">Do you have a Trading Name?<strong>*</strong></label>
                  <div class="box input-group mb-3">
                    <div class="input-group-prepend">
                      <p class="label input-group" for="trading_name" >No</p>
                    </div>
                    <input class="form-check" type="radio" v-model="hasTradingName" @change="onChange($event)" name="trading_name" :value="false">
                  </div>
                  <div class="box input-group mb-3">
                    <div class="input-group-prepend">
                      <p class="label input-group" for="trading_name" >Yes</p>
                    </div>
                    <input class="form-check" type="radio" v-model="hasTradingName" @change="onChange($event)" name="trading_name" :value="true">
                  </div>
                
                
                <div class="box2" v-if="hasTradingName == 'true'">
                  <label for="address">Trading Name:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.tradingName" >
                </div>
              </fieldset>
              <div v-if="hasTradingName == 'true'">
                <fieldset >
                  <div class="box">
                    <label for="address">Address 1:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.trading.streetNumber">
                  </div>
               
                  <div class="box">
                    <label for="business">City:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.trading.cityTown">
                  </div>
                  <div class="box">
                    <label for="business">Province:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.trading.provState">
                  </div>
                </fieldset>
             
               <fieldset>
                <div class="box2 mobile-container">
                  <label for="business">Country:<strong>*</strong></label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="business.trading.country" v-on:click="toggleCountryListforAddress" >
                  </div>
                  <div class="country-list" v-if="showCountryForAddress">
                    <div class="row">
                      <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForAddress(l)" >
                        <div>
                          <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                        </div>
                        <div class="country-name">
                          <span> {{ l.country }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box2">
                  <label for="business">Zipcode:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.trading.zipcode">
                </div>
               </fieldset>
              </div>
              
                <br>
                <fieldset>
                  <div class="box2">
                    <label for="address">Company Email address:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.emailAddress" placeholder="email@email.com">
                  </div>
                  <div class="box2">
                    <label for="address">Company Phone Number:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.phoneNumber" @keypress="isNumber($event)">
                  </div>
                </fieldset>
                <fieldset>
                  <div class="box2">
                    <label for="address">Company website:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.companyWebsite" pattern="https?://.+" placeholder="https://website.com">
                  </div>
                  <div class="box2">
                    <label for="address">Nature of Business:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.natureOfBusiness">
                  </div>
                </fieldset>
                <fieldset>
                  <h5 class="legend">Established Date</h5>
                  <div class="box">
                    <label for="day">Day:<strong>*</strong></label>
                    <select v-model="business.dateOfEstablishment.day" @change="updateDate" class="form-select">
                      <option value="">DD</option>
                      <option v-for="dayOption in days" :key="dayOption" :value="dayOption">{{ dayOption }}</option>
                    </select>
                  </div>
                  <div class="box">
                    <label for="month">Month:<strong>*</strong></label>
                    <select v-model="business.dateOfEstablishment.month" @change="updateDate" class="form-select">
                      <option value="">MM</option>
                      <option v-for="(monthOption, index) in months" :key="index" :value="index + 1">{{ monthOption }}</option>
                    </select>
                  </div>
                  <div class="box">
                    <label for="year">Year:<strong>*</strong></label>
                  <select v-model="business.dateOfEstablishment.year" @change="updateDate" class="form-select">
                    <option value="">YYYY</option>
                    <option v-for="yearOption in years" :key="yearOption" :value="yearOption">{{ yearOption }}</option>
                  </select>
                  </div>
                </fieldset>
              <br>
                <fieldset>
                  <h5 class="legend">
                    Transaction volume details
                  </h5>
                  <div class="box">
                    <label for="address">Sales volume per month:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.salesVolume" @keypress="isNumber($event)">
                  </div>
                  <div class="box">
                    <label for="address">Estimated Txn per Month:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.txnPerMonth" @keypress="isNumber($event)">
                  </div>
                  <div class="box">
                    <label for="address">Gross income (Annual):<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.grossIncome" @keypress="isNumber($event)">
                  </div>
                </fieldset>
              
                <br>

                <fieldset>
                  <h5 class="legend">
                    Company Bank Account Details
                  </h5>
                  <div class="box">
                    <label for="address"> Account Number:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.company.accountNumber" @keypress="isNumber($event)">
                  </div>
                  <div class="box">
                    <label for="business"> Account Name:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.company.accountName">
                  </div>
                  <div class="box">
                    <label for="business">Bank Name:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.company.bankName">
                  </div>

                </fieldset>
             <br>
              <hr>

              <fieldset>
                <h5 class="legend">Contact Person's Information</h5>
                <div class="box">
                  <label for="address">Full name:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.company.fullName">
                </div>
                <div class="box">
                  <label for="address">Email address:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.company.emailAddress" placeholder="email@email.com">
                </div>
              
                  <div class="box mobile-container">
                    <label for="address">Mobile Phone:<strong>*</strong></label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button class="btn btn-flag" type="button" v-on:click="toggleCountryListForContact" >
                          <img class="default-flag" :src="require('@/assets/signup_flags/' + mcountry + '.png' )">
                          <span class="text-dark">{{ mobileCode }}</span>
                       
                        </button>
                      </div>
                      <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.company.phoneNumber" @keypress="isNumber($event)">
                    </div>
                    <div class="country-list" v-if="showCountryForContact">
                      <div class="row">
                        <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForContact(l)" >
                          <div>
                            <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                          </div>
                          <div class="country-name">
                            <span> {{ l.country }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              <br>
              <hr>
              
              
              <br>
              <p class="text-white" style="color: red;">{{errorMsg}}</p>
              <button type="submit" class="btn btn-main" id="loginBtn" >Submit Business Information</button>
            </form>
          </div>

          <div v-if="this.$route.params.step == 5">
            <form v-on:submit.prevent="submitHolderKyc">
              <br>
              <h5 class="text-white">Registrant Personal Information - Authorized account holder</h5>
              <fieldset>
                <div class="box2">
                  <label for="bType">Title:<strong>*</strong></label>
                  <select id="bType" v-model="business.account.title" class="form-control form-select">
                    <option value="" disabled>Select Title</option>
                    <option value="Mr">Mr.</option>
                    <option value="Mr">Mrs.</option>
                    <option value="Ms">Ms.</option>
                  </select>
                  </div>
                <div class="box2 mobile-container">
                  <label for="loginId">Mobile Number:<strong>*</strong></label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <button class="btn btn-flag" type="button" v-on:click="toggleCountryListForMobile" :disabled="isMobile"  :class="{'disabled' : isMobile}">
                        <img class="default-flag" :src="require('@/assets/signup_flags/' + mobileCountry + '.png' )">
                        <span class="text-dark">{{ mobileCode }}</span>
                      </button>
                    </div>
                    <input type="tel" class="form-control" v-model="init.profile.mobilePhone" @keypress="isNumber($event)" :disabled="isMobile"  :class="{'disabled' : isMobile}">
                  </div>
                  <div class="country-list" v-if="showCountryForMobile">
                    <div class="row">
                      <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForMobile(l)" >
                        <div>
                          <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                        </div>
                        <div class="country-name">
                          <span> {{ l.country }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </fieldset>
              <fieldset>
                <div class="box">
                  <label for="business">First name:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.firstName">
                </div>
                <div class="box">
                  <label for="business">Middle name:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.middleName">
                </div>
                  <div class="box">
                    <label for="business">Last name:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.lastName">
                  </div>
              </fieldset>
              <fieldset>
                <h5 class="legend">Birthdate:</h5>
                <div class="box">
                  <label for="day">Day:<strong>*</strong></label>
                  <select v-model="business.account.day" @change="updateDate" class="form-select">
                    <option value="">DD</option>
                    <option v-for="dayOption in days" :key="dayOption" :value="dayOption">{{ dayOption }}</option>
                  </select>
                </div>
                <div class="box">
                  <label for="month">Month:<strong>*</strong></label>
                  <select v-model="business.account.month" @change="updateDate" class="form-select">
                    <option value="">MM</option>
                    <option v-for="(monthOption, index) in months" :key="index" :value="index + 1">{{ monthOption }}</option>
                  </select>
                </div>
                <div class="box">
                  <label for="year">Year:<strong>*</strong></label>
                <select v-model="business.account.year" @change="updateDate" class="form-select">
                  <option value="">YYYY</option>
                  <option v-for="yearOption in years" :key="yearOption" :value="yearOption">{{ yearOption }}</option>
                </select>
                </div>
              </fieldset>
             


              <fieldset>
                <h5 class="legend">Complete Address:</h5>
                <div class="box">
                  <label for="address">Street:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.address.numberStreet">
                </div>
                <div class="box">
                  <label for="address">City:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.address.cityTown">
                </div>
                <div class="box">
                  <label for="address">Province:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.address.provState">
                </div>
              </fieldset>

              <fieldset>
                <div class="box2 mobile-container">
                  <label for="business">Country:<strong>*</strong></label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="business.account.address.country" v-on:click="toggleCountryListforAccountAddress" >
                  </div>
                  <div class="country-list" v-if="showCountryForAccountAddress">
                    <div class="row">
                      <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForAccountAddress(l)" >
                        <div>
                          <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                        </div>
                        <div class="country-name">
                          <span> {{ l.country }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box2">
                  <label for="address">Zipcode:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.address.zipcode">
                </div>

              </fieldset>
              
              <hr>

              <fieldset>
                <h5 class="legend">Extended Information:</h5>
                <div class="box">
                  <label for="address">Email address:<strong>*</strong></label>
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.emailAddress" placeholder="email@email.com" :disabled="isEmail" :class="{'disabled' : isEmail}">
                </div>
                <div class="box">
                  <label for="bType">Gender:<strong>*</strong></label>
                  <select id="bType" v-model="business.account.gender" class="form-select">
                    <option value="" disabled>Select Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="O">Others</option>
                  </select>
                  </div>
                  <div class="box">
                    <label for="marital-status">Marital Status:<strong>*</strong></label>
                    <select id="marital-status" v-model="business.account.selectedMaritalStatus" class="form-select">
                      <option value="" disabled>Select Status</option>
                      <option value="S">Single</option>
                      <option value="M">Married</option>
                      <option value="D">Divorced</option>
                      <option value="W">Widowed</option>
                    </select>
                    </div>
                  </fieldset>
                  <fieldset>
                  <div class="box mobile-container">
                    <label for="business">Nationality:<strong>*</strong></label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-select" id="formGroupExampleInput" v-model="business.account.nationality"  v-on:click="toggleCountryListForNationality">
                    </div>
                    <div class="country-list" v-if="showCountryForNationality">
                      <div class="row">
                        <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForNationality(l)" >
                          <div>
                            <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                          </div>
                          <div class="country-name">
                            <span> {{ l.country }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box">
                    <label for="address">Place of Birth:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.placeOfBirth">
                  </div>
                  <div class="box">
                    <label for="address">Mother's Maiden Name:<strong>*</strong></label>
                    <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.maidenName">
                  </div>

              </fieldset>

             <fieldset>
              <div class="box2">
                <label for="bType">ID Type:<strong>*</strong></label>
                <select id="bType" v-model="business.account.idType" class="form-control">
                  <option value="">Select</option>
                  <option value="passport">Passport</option>
                  <option value="driverslicense">Driver's License</option>
                  <option value="nationalId">National ID</option>
                </select>
                </div>
                <div class="box2">
                  <label for="bType">ID number:<strong>*</strong></label>
                  <input id="bType" v-model="business.account.idNumber" class="form-control">
                </div>
             </fieldset>

             <fieldset>
              <div class="box">
                <label for="address">Nature of Work:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.natureOfWork">
              </div>
              <div class="box">
                <label for="address">Designation:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.position">
              </div>
             
              <div class="box">
                <label for="address"># of Shares:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="business.account.shares" @keypress="isNumber($event)">
              </div>

             </fieldset>
             <fieldset>
              <div class="box2">
                <label for="source-funds">Source of Funds:<strong>*</strong></label>
                <select id="source-funds" v-model="business.account.sourceOfFund" class="form-select">
                  <option value="">Select</option>
                  <option value="selfEmployed">self-employed</option>
                  <option value="salary">salary</option>
                  <option value="investment">investment</option>
                </select>
              </div>
         
              <div class="box2">
                <label for="address">Gross Monthly income:<strong>*</strong></label>
                <select id="bType" v-model="business.account.grossMonthly" class="form-select">
                  <option value="">Select</option>
                  <option value="50000">Php0 - 50,000</option>
                  <option value="100000">Php50,001 - 100,000</option>
                  <option value="500000">Php100,001 - 500,000</option>
                  <option value="500001">Php500,000 +</option>
                </select>
              </div>

             </fieldset>
                <div class="three-column">
                 

                </div>
                <div class="form-group ">
                  <p class="text-white" style="color: red;">{{errorMsg}}</p>
                  <button type="submit" class="btn btn-main" id="loginBtn" >Submit Account Holder Information</button>
                </div>
            </form>
            </div>
          <div v-if="this.$route.params.step == 6">
          
              <div class="form-group" style="color: white; padding: 20px; font-size: 1rem;">
                <h2 class="text-white">Registrant Personal Information - Authorized account holder</h2>
                <br>
                <hr>
                <br>
                <p>Take a photo of your identification card and supporting documents. The photo should be:
                  <ol style="color: white; padding: 20px; font-size: 1rem;">
                    <li>
                      bright and clear
                    </li>
                    <li>
                      all corners of the document should be visible.
                    </li>
                  </ol>
                </p>
              </div>

            
              <div class="three-column" >
                <!-- <ul class="btn-group-card-block" >
                  <li>
                    <button class="btn-card-block" :class="{ 'green': this.approvalStatus == 'APPROVED'}"  @click="openUploadId">
                      Valid ID
                    </button>
                  </li>
                 
                  <li>
                    <button class="btn-card-block" :class="{ 'green':  this.approvalStatus == 'APPROVED'}" @click="openUploadSelfie">
                      Selfie with ID
                    </button>
                  </li>
                  <li>
                    <button class="btn-card-block" :class="{ 'green':  this.approvalStatus == 'APPROVED'}" @click="openUploadDocs">
                      Utility Bill
                    </button>
                  </li>
                </ul> -->
                
              </div>
              <div>
                <br>
                <hr>
                <br>
                <label v-if="poid.length > 0">UPLOADED<strong>*</strong></label>
                <button class="btn btn-display"
                  v-for="(item, index) in poid"
                  :key="index"
                  :class="getClass(item)"
                  @click="viewId(item)"
                  
                >
                  {{ item.poidDesc }} 
                </button>
               
              </div>
              <br>
                <hr>
                <br>
              <button class="btn btn-main"  @click="openUpload" v-if="!uploaded">Upload Documents</button>
              <button class="btn btn-main" id="loginBtn" v-on:click="uploadDocs" v-if="uploaded">Next</button>
          </div>

        </div>
      </div>
      <Upload v-if="showUpload" @close="openUpload"></Upload>
      <!-- <UploadID v-if="showUpload" @close="openUploadId"></UploadID> -->
      <UploadSelfie v-if="showUploadselfie" @close="openUploadSelfie"></UploadSelfie>
      <UploadDocs v-if="showUploadDocs" @close="openUploadDocs"></UploadDocs>
      <view-id v-if="showDetails"  :poidItem="poidItem" :poidKey="poidKey"></view-id>
      
    </div>

  </div>
</template>

<script>
  import Upload from '@/components/modals/Upload.vue'
  import UploadDocs from '@/components/modals/UploadDocs.vue'
  import UploadSelfie from '@/components/modals/UploadSelfie.vue'
  import ViewId from '@/components/modals/ViewId.vue'

  export default {
    name: 'CorporateSetupComponent',
    components: {
      UploadSelfie,
      Upload,
      UploadDocs,
      ViewId
    },
   
    data: function() {
      return { 
          currentStep: 3,
          profile:{
            firstName:"",
            middleName:"",
            lastName:"",
            emailAddress:"",
            mobileNumber:""

        },
        isNumberValid: true,
        isDateValid: true,
        isEmail: false,
        isMobile: false,
        initData:"",
        birthdate:'',
        birthDate:{
              day: '',
              month: '',
              year: ''
            },
        days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
        formattedBirthdate: '',
        isUnderage: false,
        isSubmitting: false,
        errorMsg:"",
        address:{
          numberStreet:"",
          cityTown:"",
          provState:"",
          zipcode:""

        },
        extended:{
          birthOfPlace: "",
          occupation:"",
          maidenName:"",
          natureOfWork:"",
          sourceOfFunds:"",
          selectedMaritalStatus:"",
          employer:"",
          idNumber:"",
          homePhone: '11111'
        },

        errors: {
          profile: {
            msg: "",
            show: false,
           
          }
        },
        mcountry:'GB',
        ncountry:'GB',
        mobileCountry:'GB',
        accountcountry:'GB',
        nnationality:'Philippines',
        showCountry: false,
        showCountryForMobile: false,
        showCountryForAddress: false,
        showCountryForContact: false,
        showCountryForNationality: false,
        showCountryForAccountAddress: false,
        selected: 'mobile',
        country: 'GB',
        mobileCode: '44',
        hideOrShow: "Show",
        list: {
          mobileCodes: []
        },
        isError: false,
        captchaString: "",
        showUpload: false,
        showUploadselfie: false,
        showUploadDocs: false,
        numberValue: '',
        hasTradingName: false,
        business:{
          type:'',
          name:'',
          number:'',
          issuingAgency:'',
          agencySpecify:'',
          countryIssuance:'GB',
          officeAddress:{
            streetNumber:'',
            provState:'',
            cityTown:'',
            country:'GB',
            postalCode:''
          },
          tradingName:'',
          trading:{
            streetNumber:'',
            streetName:'',
            cityTown:'',
            country:'GB',
            postalCode:''
          },
          emailAddress:'',
          phoneNumber:'',
          companyWebsite:'',
          natureOfBusiness:'',
          dateOfEstablishment:{
            fullDate:'',
            day:'',
            month:'',
            year:''
          },
          salesVolume:'',
          txnPerMonth:'',
          grossIncome:'',

          company:{
            fullName:'',
            emailAddress:'',
            phoneNumber:'',
            accountNumber:'',
            accountName:'',
            bankName:''
          },
          account:{
            title:'',
            lastName:'',
            firstname:'',
            middleName:'',
            gender:'',
            day:'',
            month:'',
            year:'',
            nationality:'GB',
            cityTown:'',
            country:'GB',
            numberStreet:'',
            zipcode:'',
            provState:'',
            emailAddress:'',
            mobileNumber:'',
            idType:'',
            idNumber:'',
            position:'',
            shares:'',
            sourceOfFund:'',
            grossMonthly:'',
            address:{
              numberStreet:"",
              cityTown:"",
              provState:"",
              zipcode:"",
              country:'Philippines'

            },
            placeOfBirth:'',
            maidenName:'',
            selectedMaritalStatus:''
          },
        },
        poidDesc:'',
        approvalStatus:'',
        poid:[],
        poidItem:'',
        showDetails: false,
        poidData:'',
        poidKey:'',
        uploaded: false,
        isEmail: false,
        isMobile: false,
        initData: ''
        
    
       
      }
    },
    computed:{
      
       init() {
        	return this.$store.state.init;
    	},
    
      show() {
        return this.$store.state.show;
      },

    },
    methods: {
      getInitialSetup(){
				this.axios.get('/cx/initialsetupdata', {
					headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
				})
						.then((response) => { 
							this.init.profile.mobilePhone = response.data.mobilePhone;
							this.init.profile.emailAddress = response.data.emailAddress;
							if(this.init.profile.mobilePhone){
								this.isMobile = true
								this.initData = 'mobile';
							}
							if(this.init.profile.emailAddress){
								this.isEmail = true;
								this.initData = 'email';
							}
						
						}).catch((err) => {
							console.log(err)

						})
			},
     updateDate() {
      if (this.day && this.month && this.year) {
        // Do any additional validation or formatting if needed
        const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
        console.log('Selected Date:', formattedDate);
      }
    },
          viewId(details){
            this.poidItem = details
            this.poidKey = details.poidKey
            console.log(details);
            
            this.showDetails = !this.showDetails
            
          },
      onChange(event){
        const hasTradingName = event.target.value;
            this.hasTradingName = hasTradingName

      },
      openUpload(){
        this.showUpload = !this.showUpload
      },
      getPoidType(){
        const options = {
              method: 'GET',
              headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
              url: '/cx/poids',
            }

            this.axios(options)
                .then((response) => {               
               this.poid = response.data
                  if(this.poid != 0){
                    this.uploaded = true
                  }
                }).catch((err) => {
                console.log(err);

                })

      },
      getClass(item) {
      if (item.approvalStatus === 'APPROVED') {
        this.showUpload = false;
        this.showUploadDocs = false;
        this.showUploadselfie = false;

        if (item.poidType === 'validId' || item.poidType === 'poa' || item.poidType === 'selfie') {
          return 'green';
        }
      }

      return ''; // Return an empty string if no special class is required
    },
      validateNumber() {
        const maxValue = 2023; // Maximum value you want to allow
          if (this.business.dateOfEstablishment.year > maxValue) {
            this.business.dateOfEstablishment.year = maxValue.toString(); // Set the value to the maximum
          }
        
        },
        validateNumberAccount() {
        const maxValue = 2008; // Maximum value you want to allow
  
          if (this.business.account.year > maxValue) {
            this.business.account.year = maxValue.toString(); // Set the value to the maximum
          }
        },
        validateNumberDay() {
        const maxValue = 31; // Maximum value you want to allow
          if (this.business.dateOfEstablishment.day > maxValue) {
            this.business.dateOfEstablishment.day = maxValue.toString(); // Set the value to the maximum
          }
          if (this.business.account.day > maxValue) {
            this.business.account.day = maxValue.toString(); // Set the value to the maximum
          }
        },
        validateNumberMonth() {
        const maxValue = 12; // Maximum value you want to allow
          if (this.business.dateOfEstablishment.month > maxValue) {
            this.business.dateOfEstablishment.month = maxValue.toString(); // Set the value to the maximum
          }
          if (this.business.account.month > maxValue) {
            this.business.account.month = maxValue.toString(); // Set the value to the maximum
          }
        },

      
    
      openUploadId(){
          this.showUpload = !this.showUpload
          if(this.poidType == 'validId'){
            this.showUpload = false
          }
      },
      openUploadDocs(){
        this.showUploadDocs = !this.showUploadDocs
        if(this.poidType == 'poa'){
            this.showUploadDocs = false
          }
      },
      openUploadSelfie(){
        this.showUploadselfie = !this.showUploadselfie
        if(this.poidType == 'selfie'){
            this.showUploadselfie = false
          }
      },
      uploadDocs(){
        window.location.href = "/#/upload-document";
      },
      goToHome:function(){
        window.location.href = "/#/store";
      },
     
     

      toggleCountryList: function(){
          this.showCountry = !this.showCountry;
        
        },
       
      selectCountry: function(c) {
				this.showCountry = false;
				this.business.countryIssuance = c.countryCode;
				
			},
      toggleCountryListForMobile: function(){
          this.showCountryForMobile = !this.showCountryForMobile;
        
        },
       
      selectCountryForMobile: function(c) {
				this.showCountryForMobile = false;
				this.mobileCountry = c.countryCode;
				this.mobileCode = c.mobileCode;
			},
      toggleCountryListforAddress(){
        this.showCountryForAddress = !this.showCountryForAddress;
      },
      selectCountryForAddress: function(c) {
				this.showCountryForAddress = false;
        this.business.officeAddress.country = c.countryCode
      
			},
      toggleCountryListForContact(){
        this.showCountryForContact = !this.showCountryForContact;
      },
      selectCountryForContact: function(c) {
				this.showCountryForContact = false;
				this.mobileCode = c.mobileCode;
        this.mcountry = c.countryCode
			},
      toggleCountryListForNationality(){
        this.showCountryForNationality = !this.showCountryForNationality;
      },
      selectCountryForNationality: function(c) {
				this.showCountryForNationality = false;
        this.ncountry = c.countryCode
        this.business.account.nationality = c.country
			},
      toggleCountryListforAccountAddress(){
        this.showCountryForAccountAddress = !this.showCountryForAccountAddress;
      },
      selectCountryForAccountAddress: function(c) {
				this.showCountryForAccountAddress = false;
        this.business.account.address.country = c.country
        this.accountcountry = c.countryCode
			},
		
      getMobileCodes: function() {
      this.axios.get('/assets/mobilecodes.json')
        .then((response) => {
          this.list.mobileCodes = response.data;
        }).catch((err) => {
          console.log(err);

        })
    },

     
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      submitBusinessInfo(){
        this.isSubmitting = false
        if(this.business.type == "" || this.business.type == null || this.business.type == undefined)  {
            this.errorMsg= "Business Type is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            }

            if(this.business.name == "" || this.business.name == null || this.business.name == undefined)  {
            this.errorMsg = "Business name is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.number == "" || this.business.number == null || this.business.number == undefined)  {
            this.errorMsg = "Business number is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.issuingAgency == "" || this.business.issuingAgency == null || this.business.issuingAgency == undefined)  {
            
            this.errorMsg = "Issuing Agency is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.countryIssuance == "" || this.business.countryIssuance == null || this.business.countryIssuance == undefined)  {
            this.errorMsg = "Country is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            }
            if(this.business.officeAddress.streetNumber == "" || this.business.officeAddress.streetNumber == null || this.business.officeAddress.streetNumber == undefined)  {
            this.errorMsg = "Street is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.officeAddress.cityTown == "" || this.business.officeAddress.cityTown == null || this.business.officeAddress.cityTown == undefined)  {
            this.errorMsg = "City is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.officeAddress.country == "" || this.business.officeAddress.country == null || this.business.officeAddress.country == undefined)  {
            this.errorMsg = "Country is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.officeAddress.zipcode == "" || this.business.officeAddress.zipcode == null || this.business.officeAddress.zipcode == undefined)  {
            this.errorMsg = "Postal Code is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
          


            const params = {
              businessType: this.business.type,
              businessName: this.business.name,
              businessNumber: this.business.number,
              issuingAgency: this.business.issuingAgency,

              "officeAddress.numberStreet": this.business.officeAddress.streetNumber,
               "officeAddress.cityTown": this.business.officeAddress.cityTown,
               "officeAddress.provState": this.business.officeAddress.provState,
               "officeAddress.country": this.business.officeAddress.country,
               "officeAddress.postalCode": this.business.officeAddress.zipcode,
               issuanceCountry: this.business.countryIssuance,
           
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');


            const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'blox-authz': localStorage.getItem("tokenId") 
            }, 
            data,
            url: '/cx/profile/corp/step1',
            };

            this.axios(options)
            .then((response) => {
            console.log(response.data)
            this.isSubmitting = true
            this.$router.push({
              name: 'CorporateSetup',
              params: {
                step: 4
              }
            });
               
            }).catch((err) => {
              console.log(err.response.data)     

                if(err.response.data.fieldErrors){
                    this.errorMsg = err.response.data.fieldErrors[0].defaultMessage

                }
                 if(err.response.data.msgCode == '00001') {
                  window.location.href = "/#/";
                }  

                if(err.response.data.msgCode == '00020') {

                  setTimeout( () => {
                  window.location.href = "/#/setup-profile";
                },1000)
                  
              }  
            })
        
      },
      validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
      submitExtendedBusiness(){
     

              if (!this.validateEmail(this.business.emailAddress)) {
              this.errorMsg = "Please provide a valid Email Address.";
              }
            if(this.business.phoneNumber == "" || this.business.phoneNumber == null || this.business.phoneNumber == undefined)  {
            this.errorMsg = "Business phone number is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.companyWebsite == "" || this.business.companyWebsite == null || this.business.companyWebsite == undefined)  {
            this.errorMsg = "Business website is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.natureOfBusiness == "" || this.business.natureOfBusiness == null || this.business.natureOfBusiness == undefined)  {
            this.errorMsg = "Nature of Business is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.dateOfEstablishment == "" || this.business.dateOfEstablishment == null || this.business.dateOfEstablishment == undefined)  {
            this.errorMsg = "Date of Establishment is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.salesVolume == "" || this.business.salesVolume == null || this.business.salesVolume == undefined)  {
            this.errorMsg = "Sales Volume is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.txnPerMonth == "" || this.business.txnPerMonth == null || this.business.txnPerMonth == undefined)  {
            this.errorMsg = "Transaction Per month is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 

            if(this.business.grossIncome == "" || this.business.grossIncome == null || this.business.grossIncome == undefined)  {
            this.errorMsg = "Annual gross income is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
          

            if(this.business.company.fullName == "" || this.business.company.fullName == null || this.business.company.fullName == undefined)  {
            
            this.errorMsg = "Contact Person is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.company.emailAddress == "" || this.business.company.emailAddress == null || this.business.company.emailAddress == undefined)  {
              this.errorMsg = "Contact Person's email address is required";
                  setTimeout(()=>{
                    this.errorMsg = "";
                  }, 3000)
                  return false;
            
            }
           if (!this.validateEmail(this.business.company.emailAddress)){
                this.errorMsg = "Contact Person's email address is invalid";
                  setTimeout(()=>{
                    this.errorMsg = "";
                  }, 3000)
                  return false;
            }
          
            if(this.business.company.phoneNumber == "" || this.business.company.phoneNumber == null || this.business.company.phoneNumber == undefined)  {
            
            this.errorMsg = "Contact Person's phone number is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.company.accountNumber == "" || this.business.company.accountNumber == null || this.business.company.accountNumber == undefined)  {
            
            this.errorMsg = "Bank details is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.company.accountName == "" || this.business.company.accountName == null || this.business.company.accountName == undefined)  {
            
            this.errorMsg = "Bank details is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.company.bankName == "" || this.business.company.bankName == null || this.business.company.bankName == undefined)  {
            
            this.errorMsg = "Bank details is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 

            let params = {
              companyEmailAddress: this.business.emailAddress,
              businessPhoneNumber: this.business.phoneNumber,
              companyWebsite: this.business.companyWebsite,
              businessNature: this.business.natureOfBusiness,
              dateEstablish: `${this.business.dateOfEstablishment.month}/${this.business.dateOfEstablishment.day}/${this.business.dateOfEstablishment.year}`,
              salesPerMonth: this.business.salesVolume,
              txnPerMonth: this.business.txnPerMonth,
              grossIncomeAnnual: this.business.grossIncome,
              contactPerson: this.business.company.fullName,
              contactEmailAddress: this.business.company.emailAddress,
              contactPhoneNumber: this.mobileCode + this.business.company.phoneNumber,
              bankAccountNumber: this.business.company.accountNumber,
              bankName: this.business.company.bankName,
              bankAccountName: this.business.company.accountName,
            };

            if (this.hasTradingName == 'true') {
              params.tradingName = this.business.tradingName;
              params["tradingAddress.numberStreet"] = this.business.trading.streetNumber;
              params["tradingAddress.cityTown"] = this.business.trading.cityTown;
              params["tradingAddress.provState"] = this.business.trading.provState;
              params["tradingAddress.country"] = this.business.trading.country;
              params["tradingAddress.postalCode"] = this.business.trading.zipcode;
            } else {
              params.tradingName = "na";
              params["tradingAddress.numberStreet"] = "na";
              params["tradingAddress.cityTown"] = "na";
              params["tradingAddress.provState"] = "na";
              params["tradingAddress.country"] = "PH";
              params["tradingAddress.postalCode"] = "na";
            }

            const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');


            const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'blox-authz': localStorage.getItem("tokenId") 
            }, 
            data,
            url: '/cx/profile/corp/step2',
            };

            this.axios(options)
            .then((response) => {
            console.log(response.data)
            this.$router.push({
              name: 'CorporateSetup',
              params: {
                step: 5
              }
            });
               
            }).catch((err) => {
              console.log(err.response.data)     

                if(err.response.data.fieldErrors){
                    this.errorMsg = err.response.data.fieldErrors[0].defaultMessage

                }
                 if(err.response.data.msgCode == '00001') {
                  window.location.href = "/#/";
                }  

                if(err.response.data.msgCode == '00020') {

                  setTimeout( () => {
                  window.location.href = "/#/setup-profile";
                },1000)
                  
              }  
            })

      },
      submitBasicInfo(){
        const params = {
              title:this.business.account.title,
                firstName: this.business.account.firstName,
                lastName: this.business.account.lastName,
                middleName: this.business.account.middleName,
                birthYear: this.business.account.year,
                birthMm: this.business.account.month,
                birthDd: this.business.account.day,
                nationality: this.ncountry,
                permanentAddrSameAsPresent: true,
                mobilePhone: this.mobileCode  + this.init.profile.mobilePhone,
                "presentAddress.cityTown": this.business.account.address.cityTown,
                "presentAddress.country":this.accountcountry,
                "presentAddress.numberStreet": this.business.account.address.numberStreet,
                "presentAddress.postalCode":this.business.account.address.zipcode,
                "presentAddress.provState": this.business.account.address.provState,
                emailAddress: this.init.profile.emailAddress,
                idType: this.business.account.idType,
                identityNumber: this.business.account.idNumber,
                sourceOfFunds: this.business.account.sourceOfFund,
                natureOfWork: this.business.account.natureOfWork,
                occupation: this.business.account.position,
                employer: this.business.account.firstName,
                placeOfBirth:this.business.account.placeOfBirth,
                mothersMaidenName:this.business.account.maidenName,
                maritalStatus: this.business.account.selectedMaritalStatus,
                noOfShares: this.business.account.shares,
                grossMonthlyIncome: this.business.account.grossMonthly,
              
                }
                 

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


                const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
                data,
                url: '/cx/submitbasicinfo',
                };

                this.axios(options)
                .then((response) => {
                    console.log(response.data)
                   

                }).catch((err) => {
                console.log(err.response.data)
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.msgText
                  


                if(err.response.data.fieldErrors){
                    
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(()=>{
                          this.errors.profile.show = false;
                        this.errors.profile.msg = ""
                    }, 4000)

                }
                    
                })
                
            },

      submitHolderKyc(){
      
        let hasError = false;
           
            if(this.business.account.title == "" || this.business.account.title == null || this.business.account.title == undefined)  {
                this.errorMsg = "Title is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.lastName == "" || this.business.account.lastName == null || this.business.account.lastName == undefined)  {
                this.errorMsg = "Last name is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.firstName == "" || this.business.account.firstName == null || this.business.account.firstName == undefined)  {
                this.errorMsg = "First name is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.middleName == "" || this.business.account.middleName == null || this.business.account.middleName == undefined)  {
                this.errorMsg = "Middle name is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.gender == "" || this.business.account.gender == null || this.business.account.gender == undefined)  {
                this.errorMsg = "Gender is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.day == "" || this.business.account.day == null || this.business.account.day == undefined)  {
                this.errorMsg = "Birthday is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.month == "" || this.business.account.month == null || this.business.account.month == undefined)  {
                this.errorMsg = "Birthmonth is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.year == "" || this.business.account.year == null || this.business.account.year == undefined)  {
                this.errorMsg = "Birthyear is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.nationality == "" || this.business.account.nationality == null || this.business.account.nationality == undefined)  {
                this.errorMsg = "Nationality is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
          
            // if(this.business.account.emailAddress == "" || this.business.account.emailAddress == null || this.business.account.emailAddress == undefined)  {
            //     this.errorMsg = "Email Address is required";
            //     if (!this.validateEmail(this.business.account.emailAddress)) {
            //   this.errorMsg = "Please provide a valid Email Address.";
            //   }
            // setTimeout(()=>{
            //   this.errorMsg = "";
            // }, 3000)
            // return false;
            // } 
            if(this.business.account.idType == "" || this.business.account.idType == null || this.business.account.idType == undefined)  {
                this.errorMsg = "ID Type is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.idNumber == "" || this.business.account.idNumber == null || this.business.account.idNumber == undefined)  {
                this.errorMsg = "ID Number is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.position == "" || this.business.account.position == null || this.business.account.position == undefined)  {
                this.errorMsg = "Position in the coompany is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.shares == "" || this.business.account.shares == null || this.business.account.shares == undefined)  {
                this.errorMsg = "Number of Shares is required";
            setTimeout(()=>{
              this.errorMsg = "";
            }, 3000)
            return false;
            } 
            if(this.business.account.sourceOfFund == "" || this.business.account.sourceOfFund == null || this.business.account.sourceOfFund == undefined)  {
                this.errorMsg = "Source of Funds is required";
            setTimeout(()=>{
              this.errorMsg = "";mobileNumber
            }, 3000)
            return false;
            } 
            // if(this.business.account.grossMonthly == "" || this.business.account.grossMonthly == null || this.business.account.grossMonthly == undefined)  {
            //     this.errorMsg = "Gross monthly income is required";
            // setTimeout(()=>{
            //   this.errorMsg = "";
            // }, 3000)
            // return false;
            // } 


            setTimeout(()=>{
                this.errors.profile.show = false;
                this.errors.profile.msg = ""

            }, 3000)
          
            if(hasError == false){
              this.submitBasicInfo();
            const params = {
                title:this.business.account.title,
                firstName: this.business.account.firstName,
                lastName: this.business.account.lastName,
                middleName: this.business.account.middleName,
                birthYear: this.business.account.year,
                birthMm: this.business.account.month,
                birthDd: this.business.account.day,
                nationality: this.ncountry,
                permanentAddrSameAsPresent: true,
                homePhone: this.mobileCode  + this.init.profile.mobilePhone,
                "presentAddress.cityTown": this.business.account.address.cityTown,
                "presentAddress.country":this.accountcountry,
                "presentAddress.numberStreet": this.business.account.address.numberStreet,
                "presentAddress.postalCode":this.business.account.address.zipcode,
                "presentAddress.provState": this.business.account.address.provState,
                emailAddress: this.init.profile.emailAddress,
                idType: this.business.account.idType,
                identityNumber: this.business.account.idNumber,
                sourceOfFunds: this.business.account.sourceOfFund,
                natureOfWork: this.business.account.natureOfWork,
                occupation: this.business.account.position,
                employer: this.business.account.firstName,
                placeOfBirth:this.business.account.placeOfBirth,
                mothersMaidenName:this.business.account.maidenName,
                maritalStatus: this.business.account.selectedMaritalStatus,
                noOfShares: this.business.account.shares,
                grossMonthlyIncome: this.business.account.grossMonthly,
              
                }
                 

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


                const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
                data,
                url: '/cx/profile/updatebasic',
                };

                this.axios(options)
                .then((response) => {
                    console.log(response.data)
                   
                    this.$router.push({
              name: 'CorporateSetup',
              params: {
                step: 6
              }
            });

                }).catch((err) => {
                console.log(err.response.data)
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.msgText
                  


                if(err.response.data.fieldErrors){
                    
                    this.errors.profile.show = true;
                    this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(()=>{
                          this.errors.profile.show = false;
                        this.errors.profile.msg = ""
                    }, 4000)

                }
                    
                })
                
            }

      
      },
   
     

      sessionCheck() {
       
          const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/sessioncheck',
                }
          this.axios(options)
          .then((response) => {
           this.init.hasSession = true;
           this.getProfile();
          }).catch((err) => {
            this.init.hasSession = false;
                
                // if(err.response.data.msgCode == '00001') {
                //   window.location.href = "/#/";
                // }  
  
               
  
              })
      },


      getProfile() {
        const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/profile',
                }
                this.axios(options)
                .then((response) => {
                this.init.profile = response.data;
                console.log(response.data)
                this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                  this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                  this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
                  this.business.name = response.data.corpDetails.corpInfo.businessName
                  this.business.number = response.data.corpDetails.corpInfo.businessNumber
                  this.business.company.accountName = response.data.corpDetails.corpInfo.bankAccountName
                  this.business.company.accountNumber = response.data.corpDetails.corpInfo.bankAccountNumber
                  this.business.company.bankName = response.data.corpDetails.corpInfo.bankName
                  this.business.natureOfBusiness = response.data.corpDetails.corpInfo.businessNature
                  this.business.phoneNumber = response.data.corpDetails.corpInfo.businessPhoneNumber
                  this.business.type = response.data.corpDetails.corpInfo.businessType
                  this.business.emailAddress = response.data.corpDetails.corpInfo.companyEmailAddress
                  this.business.companyWebsite = response.data.corpDetails.corpInfo.companyWebsite
                  this.business.company,fullName = response.data.corpDetails.corpInfo.contactPerson
                  this.business.company.phoneNumber = response.data.corpDetails.corpInfo.contactPhoneNumber
                  this.business.company.emailAddress = response.data.corpDetails.corpInfo.contactEmailAddress
                  this.business.dateOfEstablishment.fullDate = response.data.corpDetails.corpInfo.dateEstablish
                  this.business.grossIncome = response.data.corpDetails.corpInfo.grossIncomeAnnual
                  this.business.countryIssuance = response.data.corpDetails.corpInfo.issuanceCountry
                  this.business.issuingAgency = response.data.corpDetails.corpInfo.issuingAgency
                  this.business.salesVolume = response.data.corpDetails.corpInfo.salesPerMonth
                
                  this.business.name = response.data.corpDetails.corpInfo.businessName
                  this.business.officeAddress.streetNumber = response.data.corpDetails.corpOfficeAddress.numberStreet
                  this.business.officeAddress.cityTown  = response.data.corpDetails.corpOfficeAddress.cityTown
                  this.business.officeAddress.country  = response.data.corpDetails.corpOfficeAddress.country
                  this.business.officeAddress.zipcode  = response.data.corpDetails.corpOfficeAddress.postalCode
                  this.business.officeAddress.provState  = response.data.corpDetails.corpOfficeAddress.provState
                    if(response.data.corpDetails.tradingAddress != 0){
                      this.business.tradingName = response.data.corpDetails.corpInfo.tradingName
                      this.business.trading.streetNumber  = response.data.corpDetails.tradingAddress.numberStreet
                      this.business.trading.cityTown  = response.data.corpDetails.tradingAddress.cityTown
                      this.business.trading.country  = response.data.corpDetails.tradingAddress.country
                      this.business.trading.zipcode  = response.data.corpDetails.tradingAddress.postalCode
                      this.business.trading.provState  = response.data.corpDetails.tradingAddress.provState
                    }
                  
                 
                  
                

                  this.business.account.title = response.data.firstName
                  this.business.account.firstName = response.data.middleName
                  this.business.account.lastName = response.data.lastName
                this.profile.emailAddress = response.data.emailAddress
                this.profile.mobileNumber = response.data.mobilePhone
                // this.day = response.data.birthDd
                // this.month = response.data.birthMm
                // this.year = response.data.birthYear
                this.extended.idNumber = response.data.identityNumber
                this.extended.placeOfBirth = response.data.placeOfBirth
                this.extended.maidenName = response.data.mothersMaidenName
                this.extended.occupation = response.data.occupation
                this.extended.employer = response.data.employer
                this.extended.natureOfWork = response.data.natureOfWork
                this.extended.sourceOfFunds = response.data.sourceOfFunds
                this.extended.selectedMaritalStatus = response.data.maritalStatus
                this.business.name = response.data.businessName

                if(response.data.presentAddress != null){
                    this.address.numberStreet = response.data.presentAddress.numberStreet
                    this.address.cityTown = response.data.presentAddress.cityTown
                    this.address.provState = response.data.presentAddress.provState
                    this.address.zipcode = response.data.presentAddress.postalCode
                }
              
               
              

                
                }).catch((err) => {
                    this.init.hasSession = false;
                })
            },
    },
    mounted() {
    this.sessionCheck();
     this.getMobileCodes();
     this.getPoidType();
     this.getInitialSetup();
    },
    watch: {
     
    }
  }
</script>
 
<style scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .disabled{
    cursor: not-allowed !important;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
.form-check{
  width: 1rem;
  height: 1rem;
}
  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    max-width: 1000px;
    min-width: 350px !important;
    margin: 10px auto;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    z-index: 2;
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}

.form-check{
  width: 3rem;
  
}
p{
  color: white;
  padding-right: 2em;
}
   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px !important;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    width: 250px;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 250px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>