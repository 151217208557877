<template>
  <div class="backdrop">
    <img :src="require('../../assets/images/bg1.jpg')" class="bg" />
    <a href="/">
      <img class="logo" :src="require('@/assets/logo.png')" >
    </a> 
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="mt-2 text-white">Upload</h5>
            <!-- <p @click="closeModal" class="x">x</p> -->
        </div>
      <div class="modalBody">
        <div class="" v-if="step == '0'" ><button @click.prevent="uploadNow()">Continue</button>
            <div class="form-group" style="color: white; padding: 5px; font-size: 1rem;" >
                <p>Take a photo of your identification card and supporting documents. 
                  <ol style="color: white; padding: 20px; font-size: 1rem;">
                    <li>
                       VALID ID
                    </li>
                    <li>
                       SELFIE HOLDING YOUR VALID ID
                    </li>
                    <li>
                      PROOF OF ADDRESS
                   </li>
                  </ol>
                </p>
                  
                  
                  
                  
                  <p>The photo should be:
                  <ol style="color: white; padding: 20px; font-size: 1rem;">
                    <li>
                       bright and clear
                    </li>
                    <li>
                       all corners of the document should be visible.
                    </li>
                  </ol>
                  <label><strong style=" font-family: 'Light';">Valid placement</strong></label>
                  <div class="wrapper" >
                    
                    <div class="product-grid" >
                      <div  class="product-card">
                        <img :src="require('../../assets/id/pass-y.png')"
                        class="product-image"
                        img-top>
                      </div>
                     
                      <div  class="product-card">
                        <img :src="require('../../assets/id/doc-yes.png')"
                        class="product-image"
                        img-top>
                      </div>
                      <div  class="product-card">
                        <img :src="require('../../assets/id/id-yes.png')"
                        class="product-image"
                        img-top>
                      </div>
                      <div  class="product-card">
                        <img :src="require('../../assets/id/selfie-yes.png')"
                        class="product-image"
                        img-top>
                      </div>
                      
  
                    </div>  
                  </div>
  
                  <label style=" font-family: 'Light';">Invalid placement</label>
                  <div class="wrapper" >
                    
                    <div class="product-grid" >
                      <div  class="product-card">
                        <img :src="require('../../assets/id/pass-n.png')"
                        class="product-image"
                        img-top>
                        <img :src="require('../../assets/id/pass-n2.png')"
                        class="product-image"
                        img-top>
                      </div>
                     
                      <div  class="product-card">
                        <img :src="require('../../assets/id/doc-n1.png')"
                        class="product-image"
                        img-top>
                        <img :src="require('../../assets/id/doc-n2.png')"
                        class="product-image"
                        img-top>
                      </div>
                      <div  class="product-card">
                        <img :src="require('../../assets/id/id-no.png')"
                        class="product-image"
                        img-top>
                        <img :src="require('../../assets/id/id-n1.png')"
                        class="product-image"
                        img-top>
                      </div>
                      <div  class="product-card">
                        <img :src="require('../../assets/id/selfie-n.png')"
                        class="product-image"
                        img-top>
                        <img :src="require('../../assets/id/selfie-n1.png')"
                        class="product-image"
                        img-top>
                      </div>
                      
  
                    </div>  
                  </div>
                
                </p>
                
  
              </div>
          
     

        </div>
           <div v-if="step == '5'" class="mt-5">
                <p class="text-white text-center">Upload successful. You will be notified once it has been approved.</p>
                <p>
                <button class="submit btn btn-main" @click="done">Done</button>
                </p>
            </div>
            

         <form v-on:submit.prevent="uploadImage($event)" v-if="step == '1'">
          <p v-if="errors.profile.show" style="color: red;">{{errors.profile.msg}}</p>
             <label for="idType">ID Type:</label>
                   
                <div class="">
                <form class="form">
                     <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="idType"
                      type="radio"
                      value="passport"
                      
                    />
                    <label class="form-check-label" for="passport">
                      Passport
                    </label>
                  </div>

                   <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="idType"
                      type="radio"
                      value="nationalId"
                     
                      
                    />
                    <label class="form-check-label" for="nationalId">
                      National ID
                    </label>
                  </div>

                   <div class="form-check">
                    <input
                      class="form-check-input"
                      v-model="idType"
                      type="radio"
                      value="driversLicense"
                      
                    />
                    <label class="form-check-label" for="driversLicense">
                     Driver's License
                    </label>
                  </div>


              
                </form>
                </div>
                    

          
            <div class="buttonWrapper" >
                <div v-if="isLoading" class="text-center">
                    <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>
                <label v-if="idType == 'nationalId' || idType == 'driversLicense'"> {{idType}} (Front)</label>
                <label v-else> {{idType}} </label>

                  <b-form-file
                    v-model="uploadFieldName"
                    :state="Boolean(uploadFieldName)"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    class="text-white"
                  ></b-form-file>


                <!-- <div class="dropbox">
                    <input type="file" enctype="multipart/form-data" :name="uploadFieldName" accept="image/*"  id="file-input" class="submit mt-3"><span v-if="isSuccess">uploaded</span>
                       
                </div> -->
                 

            </div>
            <div>
                <button type="submit" class="btn btn-main text-white" id="saveBtn">Submit</button>
            </div>
            

            
        </form>
           <form v-on:submit.prevent="uploadImageBack($event)" v-if="step == '2'">
            <p v-if="errors.profile.show" style="color: red;">{{errors.profile.msg}}</p>
             
                    <div class="buttonWrapper">
                        <div v-if="isLoading" class="text-center">
                                <b-spinner type="grow" label="Loading..."></b-spinner>
                        </div>
                        
                        <label>{{idType}} (Back)</label>
                        <b-form-file
                            v-model="uploadFieldNameBack"
                            :state="Boolean(uploadFieldNameBack)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            class="text-white"
                        ></b-form-file>
                        <!-- <div class="dropbox">
                            <input type="file" enctype="multipart/form-data" :name="uploadFieldNameBack" accept="image/*"  id="file-input" class="submit mt-3"><span v-if="isSuccess">uploaded</span>
                        <div v-if="isLoading">
                            <b-icon
                            icon="arrow-clockwise"
                            animation="spin"
                            font-scale="4"
                            ></b-icon
                            >Uploading..
                        </div>
                        </div> -->
                        
                          
                    </div>
                    <div>
                         <button type="submit" class="btn btn-main" id="saveBtn">Submit</button>
                    </div>

                

                </form>

                <form v-on:submit.prevent="uploadSelfie($event)" v-if="step == '3'">
                  <p v-if="errors.profile.show" style="color: red;">{{errors.profile.msg}}</p>
                  <label for="idType">Document Type:</label>
                        
                     <div class="">
                     <form class="d-block justify-content-between">
                        
                       <div class="form-check">
                         <input
                           class="form-check-input"
                           v-model="idTypeSelfie"
                           type="radio"
                           value="selfie"
                           selected
                           
                         />
                         <label class="form-check-label" for="selfie">
                         Selfie holding a valid ID
                         </label>
                       </div>
                     </form>
                     </div>
                         
     
               
                 <div class="buttonWrapper" >
                     <div v-if="isLoading" class="text-center">
                       <b-spinner type="grow" label="Loading..."></b-spinner>
                     </div>
                     <label > {{idTypeSelfie}} </label>
     
                       <b-form-file
                         v-model="uploadFieldNameSelfie"
                         :state="Boolean(uploadFieldName)"
                         placeholder="Choose a file or drop it here..."
                         drop-placeholder="Drop file here..."
                         class="text-white"
                       ></b-form-file>
     
     
                     <!-- <div class="dropbox">
                         <input type="file" enctype="multipart/form-data" :name="uploadFieldName" accept="image/*"  id="file-input" class="submit mt-3"><span v-if="isSuccess">uploaded</span>
                            
                     </div> -->
                      
     
                 </div>
                 <div>
                     <button type="submit" class="btn btn-main text-white" id="saveBtn">Submit</button>
                 </div>
                 
     
                 
             </form>


             <form v-on:submit.prevent="uploadPoa($event)" v-if="step == '4'">
              <p v-if="errors.profile.show" style="color: red;">{{errors.profile.msg}}</p>
              <label for="idType">Document Type:</label>
                    
                 <div class="">
                 <form class="d-block justify-content-between">
                      <div class="form-check">
                     <input
                       class="form-check-input"
                       v-model="idTypePoa"
                       type="radio"
                       value="poa"
                       
                     />
                     <label class="form-check-label" for="passport">
                      Proof of Address
                     </label>
                   </div>
 
                   
                 </form>
                 </div>
                     
 
           
             <div class="buttonWrapper" >
                 <div v-if="isLoading" class="text-center">
                    <b-spinner type="grow" label="Loading..."></b-spinner>
                 </div>
                 <label> {{idTypePoa}} </label>
 
                   <b-form-file
                     v-model="uploadFieldNamePoa"
                     :state="Boolean(uploadFieldName)"
                     placeholder="Choose a file or drop it here..."
                     drop-placeholder="Drop file here..."
                     class="text-white"
                   ></b-form-file>
 
 
                 <!-- <div class="dropbox">
                     <input type="file" enctype="multipart/form-data" :name="uploadFieldName" accept="image/*"  id="file-input" class="submit mt-3"><span v-if="isSuccess">uploaded</span>
                        
                 </div> -->
                  
 
             </div>
             <div>
                 <button type="submit" class="btn btn-main text-white" id="saveBtn">Submit</button>
             </div>
             
 
             
         </form>
         
       
          <!--FAILED-->
          <div v-if="isFailed">
            <h2 class="text-white">Upload failed.</h2>
            <h5 style="color:red;">{{ uploadError }}</h5>
            <p>
              <a href="javascript:void(0)">Try again</a>
            </p>
          </div>
          <div class="text-center">
            <img class="iconImg" >
         </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    components: {

    },
    data: function() {
        return {
            step:'0',
            idType:'',
            idTypePoa:'',
            idTypeSelfie: '',
            description:'validId',
            idNumber:'111',
            uploadFieldName: null,
            uploadFieldNameBack: null,
            uploadFieldNameSelfie: null,
            uploadFieldNamePoa: null,
            isSuccess: false,
            bothSuccess: false,
            isFailed: false,
            isLoading: false,
            response:'',
            errors: {
                profile: {
                    show:false,
                    msg: ""
                },
            },
            
        }
    },

    computed: {
     
    },

    mounted() {
     
    },

    watch: {
      
    },

    methods: {
        uploadNow(){
          this.step = '1'
        },
        closeModal(){
            this.$emit("close")
        },

        done(){
            this.$router.push({name: 'Dashboard'})
        },
         uploadImage(event) {
          let hasError = false
           if(this.idType == "" || this.idType == null || this.idType == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "ID Type is required";
            return hasError = true;
            setTimeout(() => {
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
              return hasError = false;
            }, 2000);
            }
            if(this.idNumber == "" || this.idNumber == null || this.idNumber == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "This is required";
            return hasError = true;
            setTimeout(() => {
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
              return hasError = false;
            }, 2000);
            }
            

           

         let data = new FormData();
                    data.append('poidImageFile', this.uploadFieldName);
                    data.append('poidType', this.idType);
                    data.append('poidDescription',this.description); 
                    data.append('poidNumber', this.idNumber);
                    

            
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'multipart/form-data',
                    'blox-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/cx/uploadpoid',
            };

           this.axios(options).then(
                response => {
                   // console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
                    this.errors.profile.show = false;
                   this.errors.profile.msg = "";
                    if(this.response = 200){
                       
                        setTimeout( () => {
                            this.isLoading = false
                            if(this.idType != 'passport'){
                                this.step = '2'
                             } 
                            
                             if(this.idType === 'passport'){
                                this.step = '3'
                             }
                             
                              
                          },1000)


                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                          this.errors.profile.show = false;
                          this.errors.profile.msg = "";
                        }, 2000)
                    }
                    


                }
            ).catch((err) => {
                    if(this.response = 500){
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                          this.errors.profile.show = false;
                          this.errors.profile.msg = "";
                        }, 2000)
                    }

            })
        },
        uploadImageBack(event) {
          let hasError = false
           if(this.idType == "" || this.idType == null || this.idType == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "ID Type is required";
            return hasError = true;
            setTimeout(() => {
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
              return hasError = false;
            }, 2000);
            }
            if(this.idNumber == "" || this.idNumber == null || this.idNumber == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "This is required";
            return hasError = true;
            setTimeout(() => {
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
              return hasError = false;
            }, 2000);
            }
          

             let data = new FormData();
                    data.append('poidImageFile', this.uploadFieldNameBack);
                    data.append('poidType', this.idType);
                    data.append('poidDescription',this.description); 
                    data.append('poidNumber', this.idNumber);
                    

            
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'multipart/form-data',
                    'blox-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/cx/uploadpoid',
            };

           this.axios(options).then(
                response => {
                   // console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
                    this.errors.profile.show = false;
                    this.errors.profile.msg = "";
                    if(this.response = 200){
                        
                        setTimeout( () => {
                            this.isLoading = false
                       
                            this.step = '3'

                        },3000)

                        
                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                          this.errors.profile.show = false;
                          this.errors.profile.msg = "";
                        }, 2000)
                    }
                    


                }
            ).catch((err) => {
                    if(this.response = 500){
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                          this.errors.profile.show = false;
                          this.errors.profile.msg = "";
                        }, 2000)
                    }

            })
        },
        uploadSelfie(event) {
          let hasError = false
           if(this.idTypeSelfie == "" || this.idTypeSelfie == null || this.idTypeSelfie == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Document Type is required";
            return hasError = true;
            setTimeout(() => {
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
              return hasError = false;
            }, 2000);
            }
            if(this.idNumber == "" || this.idNumber == null || this.idNumber == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "This is required";
            return hasError = true;
            setTimeout(() => {
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
              return hasError = false;
            }, 2000);
            }

           

         let data = new FormData();
                    data.append('poidImageFile', this.uploadFieldNameSelfie);
                    data.append('poidType', this.idTypeSelfie);
                    data.append('poidDescription','selfie-with-id'); 
                    data.append('poidNumber', this.idNumber);
                    

            
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'multipart/form-data',
                    'blox-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/cx/uploadpoid',
            };

           this.axios(options).then(
                response => {
                   // console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
                    this.errors.profile.show = false;
                    this.errors.profile.msg = "";
                    if(this.response = 200){
                       
                        setTimeout( () => {
                            this.isLoading = false
                           this.step = '4'
                              
                          },1000)


                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                          this.errors.profile.show = false;
                          this.errors.profile.msg = "";
                        }, 2000)
                    }
                    


                }
            ).catch((err) => {
                    if(this.response = 500){
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                          this.errors.profile.show = false;
                          this.errors.profile.msg = "";
                        }, 2000)
                    }

            })
        },
        uploadPoa(event) {
          let hasError = false
           if(this.idTypePoa == "" || this.idTypePoa == null || this.idTypePoa == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "Document Type is required";
            return hasError = true;
            setTimeout(() => {
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
              return hasError = false;
            }, 2000);
            }
            if(this.idNumber == "" || this.idNumber == null || this.idNumber == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "This is required";
            return hasError = true;
            setTimeout(() => {
              this.errors.profile.show = false;
              this.errors.profile.msg = "";
              return hasError = false;
            }, 2000);
            }

           

         let data = new FormData();
                    data.append('poidImageFile', this.uploadFieldNamePoa);
                    data.append('poidType', this.idTypePoa);
                    data.append('poidDescription','proof-of-address'); 
                    data.append('poidNumber', this.idNumber);
                    

            
            const options = {
                method: 'POST',
                headers: {
                    'content-type': 'multipart/form-data',
                    'blox-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/cx/uploadpoid',
            };

           this.axios(options).then(
                response => {
                   // console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
             
                          this.errors.show = false
                          this.errors.msg = ""
                    if(this.response = 200){
                       
                        setTimeout( () => {
                            this.isLoading = false
                           this.step = '5'
                              
                          },1000)


                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                          this.errors.show = false
                          this.errors.msg = ""
                        }, 2000)
                    }
                    


                }
            ).catch((err) => {
                    if(this.response = 500){
                        this.isFailed = true
                        setTimeout(()=>{
                          this.isFailed = false
                        }, 2000)
                    }

            })
        },
      
    }
}
</script>

<style scoped>
.backdrop {
  position: fixed; 
  z-index: 98; 
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

.b-modal {
  background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
  margin: 5% auto; 
  padding: 20px;
   max-width: 700px; 
   min-width: 250px;
   width: 100%;
}
/* .backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal{
  max-width: 700px;
    height: auto;
    margin: 100px auto;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    z-index: 2;
    padding-bottom: 50px;
    font-family: 'Light';
    width: 100%;
  } */

.modal-header {
  /* max-width: 1000px; */
   /* min-width: 500px; */
    background-color: #0E111E;
    width: 100%;
    padding: 2px 10px;
    margin-right:0px;
    font-size:15px !important;
    display:flex;
    justify-content:space-between;
    font-family: 'Light';
}

.modal-title{
    margin: 0 auto;
    color:white;
}

.modalBody {
  background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    width:100%;
    padding:10px;
    border-radius: 10px;
    font-family: 'Light';
    /* max-width: 1000px;
   min-width: 500px; */
}

.buttonWrapper {
    width:100%;
    font-family: 'Light';
}

.x {
    cursor:pointer;
}

 .form-group{
        width: 100%;
    }

    .btn-main{
        margin: 30px auto;
        width: 100%;
        font-family: 'Light';
    }
    .btn-main:hover{
        color: white;
        font-family: 'Light';
    }
 


    @media screen and (max-width: 1100px) {
   
      .form{
          display: flex;
        }
    }
   @media screen and (max-width: 800px) {
    .form{
          display: flex;
        }
        
  }

    @media screen and (max-width: 600px) {
      .b-modal {
            width: 250px;
           
        }
      .form{
          display: block;
        }
        .btn-main{
        margin: 30px auto;
        width: 100px;
        font-family: 'Light';
    }
        
    }

    @media screen and (min-width: 260px) and (max-width: 420px) {

       .b-modal {
            width: 250px;
           
        }
        .btn-main{
        margin: 30px auto;
        width: 100px;
        font-family: 'Light';
    }

        

    }

    @media screen and (min-width: 480px) and (max-width: 580px) {
        .b-modal {
          width: 350px;
        }
        .form{
          display: block;
        }
        .btn-main{
        margin: 30px auto;
        width: 100px;
        font-family: 'Light';
    }

       
        
    }
    .wrapper{
        display: flex;
         padding: 2em;
        justify-content: center !important;
        margin: 0 auto !important;
        width: 100%;
    }
   
      .product-grid {
        display: flex;
       
      }

    .product-card {
      background-color: #0E111E;
        box-shadow:0 0 30px rgba(0,0,0,0.3);
        border: 1px solid transparent;
        border-radius: 12px;
        width: 7rem;
        height: auto;
        text-align: center;
        margin: 10px !important;
        color: white;
        cursor: none;
        font-size: 10px;
    
    }


    .product-image {
      padding: 10px 0px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin: 0 auto;
        background-color: none !important;
      width: 100%;
      max-width: 80px;
      height: auto;
      margin-bottom: 10px;
    }
    .logo{
      width: 5rem;
      padding: 10px;
    }
</style>