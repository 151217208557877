<template>
  <div id="app">
   
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  },
  computed: {
        init() {
            return this.$store.state.init;
        },
  },
  methods:{
    getLocation: function(ip) {
        const options = {
            method: 'GET',
            url: '/api-loc/json/' + ip,
            };
            this.axios(options)
                .then((response) => { 

                  this.init.currentLocation = response.data.countryCode == '-' ? "GB" : response.data.countryCode;
                 
                  switch(response.data.countryCode) {
                    case "-":
                        window.location.href = "/#/gb";
                        break;
                    default:
                      window.location.href = "/#/" + response.data.countryCode.toLowerCase();
                      break;
                  }
                })
                .catch((error) => {
                  console.log(error)
                 
                });
      },
      getIp: function() {

         const options = {
            method: 'GET',
            url: '/json',
            };
            this.axios(options)
                .then((response) => { 

                  this.getLocation(response.data.ip)
                })
                .catch((error) => {
                  console.log(error)
                 
                });
        },
  },
  mounted(){
    this.getIp();
  }
}
</script>

<style>


</style>
